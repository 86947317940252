import api from "../../api/index";
import _ from "lodash";
import { getDocs } from "firebase/firestore";

// initial state
const state = () => ({
  singleCustomer: {},
});

// getters
const getters = {};

// actions
const actions = {
  /**
   * Represents a book.
   * @param {object} options
   * @param {object} options.filters - Order filter
   * @param {string} options.filters.phone - Search customer by phone
   * @param {string} options.filters.name - Search customer by name
   * @param {string} options.filters.address - Search customer by both home and work address
   * @param {int} [options.count=1]
   * @param {string} [options.orderby=name] -
   * @param {string} [options.order=ASC] - [ ASC, DESC ]
   */
  async get({ commit }, options) {
    const querySnapshot = await api.customers.getCustomers(
      Object.assign(options, { count: 1 })
    );

    if (querySnapshot.size >= 1) {
      const _doc = querySnapshot.docs[0];
      const _customer = _doc.data();
      _customer["customerId"] = _doc.id;
      commit("setCustomer", _customer);
    } else {
      commit("setCustomer", {});
    }
  },

  async getSingle({ commit }, customerId) {
    const docSnapshot = await api.customers.getCustomer(customerId);

    if (docSnapshot.exists) {
      const _customer = docSnapshot.data();
      _customer["customerId"] = docSnapshot.id;
      commit("setCustomer", _customer);
    }
  },

  async check({ commit }, options) {
    const querySnapshot = await api.customers.getCustomers(
      Object.assign(options, { count: 1 })
    );
    return querySnapshot.size >= 1;
  },

  async set(context, { customerId, data }) {
    if (customerId) {
      // Update latest orders associate with that customer
      const orderQuery = await api.orders.getOrders({
        filters: { customerId },
        order: "desc",
        count: 20,
      });

      const orderSnapShot = await getDocs(orderQuery);

      if (orderSnapShot.size > 0) {
        orderSnapShot.forEach((doc) => {
          let _order = doc.data();
          _order.customer = _.pick(data, [
            "name",
            "phone",
            "otherPhones",
            "otherPhoneDescriptions",
          ]);
          api.orders.setOrder(doc.id, _order);
        });
      }
    }

    return api.customers.setCustomer(customerId, data);
  },

  async addPassenger(context, { customerId, passenger }) {
    return api.customers.addPassenger(customerId, passenger);
  },

  async updatePassengers(context, { customerId, oldPassenger, newPassenger }) {
    return api.customers.updatePassenger(
      customerId,
      oldPassenger,
      newPassenger
    );
  },
  async deletePassenger(context, { customerId, passenger }) {
    return api.customers.deletePassenger(customerId, passenger);
  },

  async delete(context, customerId) {
    const querySnapshot = await api.orders
      .getOrders({
        filters: { customerId },
      })
      .get();

    if (querySnapshot.size > 0) {
      return {
        error:
          "This customer has at least one order! You cannot delete this customer",
      };
    }

    return api.customers.deleteCustomer(customerId);
  },
};

// mutations
const mutations = {
  setCustomer(state, val) {
    state.singleCustomer = val;
  },
  resetCustomer(state) {
    state.singleCustomer = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
