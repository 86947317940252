import { Timestamp } from "firebase/firestore";
import { onSnapshot, getCountFromServer, getDocs } from "firebase/firestore";
import { isArray, cloneDeep } from "lodash";
import api from "../../api/index";

// initial state
const state = () => ({
  singleOrder: {},
  orders: [],
  mobileOrders: [],
  monthlyTotal: {},
  dailyTotal: {},
  recentOrders: [],
  driverOrders: [],
  unsubscribe: null,
  orderCount: 0,
  cloneOrder: null,
});

// getters
const getters = {};

// actions
const actions = {
  /**
   * @param {object} options
   * @param {string} options.stateKey
   * @param {bool} options.realtime
   * @param {object} options.filters - Order filter
   * @param {string} options.filters.customerId - Filter by specific customer
   * @param {date} options.filters.startDate - Show order of date range
   * @param {date} options.filters.endDate - Show order of date range
   * @param {string} options.filters.driverId
   * @param {int} [options.count=3]
   * @param {string} [options.orderby=date] - Possible options: date, driver, customer
   * @param {string} [options.order=ASC] - [ ASC, DESC ]
   */
  async get({ commit, state }, options) {
    if (options.realtime && state.unsubscribe) {
      // Unsubscribe the active listener before creating a new connection
      const unKey = `${options.stateKey ?? "orders"}unSub`;
      state.unsub[unKey]?.();
    }

    function compileOrders(snap) {
      const _orders = [];
      snap.forEach((doc) => {
        const _order = doc.data();
        _order["orderId"] = doc.id;

        // We want to show all confirmed orders in the sorting page instead of the mobile page
        // However, firebase does not allow multiple "<=, !=" conditions on differenet fields
        // so we will handle it on the client side

        const isConfirmed =
          _order.status.toLowerCase() == "confirmed" || _order.adminConfirmAt;

        const regularOrders =
          !options.fromMobile &&
          (options.mobileOnly
            ? _order.fromMobile && isConfirmed
            : !_order.fromMobile || isConfirmed);

        const mobileOrders = options.fromMobile && !_order.adminConfirmAt;
        const mobileCheck = regularOrders || mobileOrders;

        const isMainPages = ["orders", "mobileOrders"].includes(
          options.stateKey
        );

        if (!isMainPages || mobileCheck) {
          _orders.push(_order);
        }
      });

      if (options.countResults) {
        commit("setOrderCount", _orders.length);
      } else {
        commit("setOrders", { val: _orders, key: options.stateKey });
      }
    }

    // Order return will be real time update and update the state when the remote is updated.
    const query = await api.orders.getOrders(options);

    if (options.realtime) {
      const unsubscribe = onSnapshot(query, (querySnapshot) => {
        compileOrders(querySnapshot);
      });
      commit("setUnsubscribe", { val: unsubscribe, key: options.stateKey });
    } else {
      const querySnapshot = await getDocs(query);
      compileOrders(querySnapshot);
    }
  },

  async getSingle({ commit }, options) {
    const querySnapshot = await getDocs(
      api.orders.getOrders(Object.assign(options, { count: 1 }))
    );

    if (querySnapshot.size == 1) {
      const _doc = querySnapshot.docs[0];
      const _order = _doc.data();
      _order["orderId"] = _doc.id;
      commit("setSingleOrders", _order);
    }
  },

  async getCount({ commit }, options) {
    const query = await api.orders.getOrders(Object.assign(options));

    const querySnapshot = await getCountFromServer(query);
    commit("setOrderCount", querySnapshot.data().count);
  },

  async getMonthlyOrdersTotal({ commit }) {
    const docSnapshot = await api.orders.getMonthlyOrdersTotal();
    const _total = docSnapshot.data();
    commit("setMonthlyTotal", _total);
  },

  async getDailyOrdersTotal({ commit }) {
    const docDailySnapshot = await api.orders.getDailyOrdersTotal();
    const _dailyTotal = docDailySnapshot.data();
    commit("setDailyTotal", _dailyTotal);
  },

  async set({ state }, { orderId, data, user }) {
    let _cid = data.customerId;

    if (data.payment_method) {
      api.settings.addSetting("payment_methods", data.payment_method);
    }

    if (data.luggage_type) {
      api.settings.addSetting("luggage_types", data.luggage_type);
    }

    if (!orderId) {
      data.createdBy = user.name;
      data.createdAt = Timestamp.fromDate(new Date());
    }

    if (user) data.updatedBy = user.name;
    data.updatedAt = Timestamp.fromDate(new Date());

    if (!_cid && data.customer.phone) {
      // Create new customer if custmoerId is null
      const querySnapshot = await api.customers.getCustomers({
        filters: { phone: data.customer.phone },
      });

      if (querySnapshot.size >= 1) {
        return {
          error:
            "This customer's phone number already exist! Please check and try again",
        };
      }

      const _customer = Object.assign({}, data.customer, {
        addresses: data.locations ?? [],
        passengers:
          isArray(data.passenger) && data.passenger.length > 0
            ? data.passenger
            : [],
        payment_method: data.payment_method ?? null,
      });
      const docRef = await api.customers.setCustomer(null, _customer);
      _cid = docRef.id;
      data.customerId = _cid;
    } else if (
      isArray(data.passenger) &&
      Object.keys(data.passenger).length > 0
    ) {
      // Update customer passenger list
      const csSnap = await api.customers.getCustomer(_cid);
      const _customer = csSnap.data();
      const _phones = _customer?.passengers?.map((p) => p.phone) ?? [];

      const _passengers = data.passenger;

      for (var i = _passengers.length - 1; i >= 0; i--) {
        // If the passenger phone number already exist, then we will update with the latest data

        if (_phones.indexOf(_passengers[i].phone) >= 0) {
          const _idx = _phones.indexOf(_passengers[i].phone);
          await api.customers.deletePassenger(_cid, _customer.passengers[_idx]);
        }
        await api.customers.addPassenger(_cid, data.passenger);
      }
    }
    return api.orders.setOrder(orderId, data);
  },

  async delete(context, orderId) {
    return api.orders.deleteOrder(orderId);
  },

  async clone({ commit }, order) {
    // The cloned order will always be a normal order
    const cloneOrder = cloneDeep(order);
    cloneOrder["fromMobile"] = false;

    if (cloneOrder) {
      commit("setCloneOrder", cloneOrder);
    }
  },
};

// mutations
const mutations = {
  setUnsubscribe(state, { val, key = "orders" }) {
    state.unsub ??= {};
    state.unsub[key]?.();
    state.unsub[key] = val;
  },
  setOrders(state, { val, key = "orders" }) {
    state[key] = val;
  },
  resetOrders(state, { key = "orders" }) {
    state[key] = [];
  },
  setSingleOrders(state, val) {
    state.singleOrder = val;
  },
  setOrderCount(state, val) {
    state.orderCount = val;
  },
  setMonthlyTotal(state, val) {
    state.monthlyTotal = val;
  },
  setDailyTotal(state, val) {
    state.dailyTotal = val;
  },
  setCloneOrder(state, val) {
    state.cloneOrder = val;
  },
  resetCloneOrder(state) {
    state.cloneOrder = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
