<template>
  <div
    id="navigation"
    :class="{
      collapsed: collapsed,
      'd-flex': true,
      'flex-column': true,
    }"
  >
    <div class="brand">
      <a href="#" v-on:click.prevent="collapsedMenu">
        <b-icon-list />
      </a>
      <router-link class="menu-link" to="/">Order Taking</router-link>
    </div>
    <nav>
      <a href="/order">
        <b-icon-plus-circle />
        <span class="menu-link">Input</span>
      </a>
      <router-link to="/orders">
        <b-icon-box-seam />
        <span class="menu-link">Sorting</span>
      </router-link>
      <router-link to="/mobile">
        <a class="icon-wrapper">
          <b-icon-phone />
          <b-badge class="icon-badge" variant="danger">{{
            this.orderCount
          }}</b-badge>
        </a>
        <span class="menu-link">Mobile Orders</span>
      </router-link>
      <router-link to="/customers">
        <b-icon-search />
        <span class="menu-link">Search</span>
      </router-link>
      <router-link to="/drivers" v-if="isAdmin">
        <b-icon-person-circle />
        <span class="menu-link">Driver</span>
      </router-link>
      <router-link to="/settings" class="mt-5">
        <b-icon-gear />
        <span class="menu-link">Settings</span>
      </router-link>
      <a href="#" @click.prevent="logout">
        <b-icon-power />
        <span class="menu-link">Logout</span>
      </a>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      collapsed: localStorage.menuCollapsed,
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
      orderCount: (state) => state.orders.orderCount,
    }),
    isAdmin() {
      return [
        "6emDExLlw0TqWKt4XbaT1FO04r43",
        "LdsJSZSfYZTq4rRuaBT3BDy0GI63",
        "TBXIC3KN5lSbArAIQAIaOzcvqPG3",
        // "itjnMqfFqQYmlHvtlCb9SRGO6jR2",
      ].includes(this.userId);
    },
  },
  methods: {
    ...mapActions({
      getOrder: "orders/get",
    }),
    collapsedMenu() {
      this.collapsed = !this.collapsed;
      localStorage.menuCollapsed = this.collapsed;

      if (this.collapsed) this.$emit("collapsed");
      else this.$emit("expanded");
    },
    logout() {
      this.$store.dispatch("user/logout");
    },
    async getMobileOrderCount() {
      await this.getOrder({
        realtime: true,
        countResults: true,
        fromMobile: true,
        stateKey: "orderCount",
        filters: {
          startDate: moment().toDate(),
          statusSimple: "waiting",
        },
      });
    },
  },
  created() {
    this.getMobileOrderCount();
  },
};
</script>

<style lang="scss">
#navigation {
  padding-top: 35px;
  padding-left: 15px;
  width: 200px;
  height: 100%;
  overflow: hidden;
  transition: 0.3s width;

  &,
  a {
    color: #fff;
    font-weight: bold;
    white-space: nowrap;
  }

  .brand {
    margin-bottom: 30px;
    line-height: 30px;

    a {
      font-size: 1.4rem;
      display: inline-block;
    }

    svg {
      font-size: 1.85rem;
      margin-right: 15px;
      line-height: 30px;
      vertical-align: -0.25em;
    }
  }

  a {
    text-decoration: none;

    &:after {
      content: none;
    }
  }

  nav {
    flex-grow: 1;
    flex-direction: column;
    display: flex;
    width: 150px;
    text-transform: uppercase;

    > a {
      display: block;
      padding: 12px 0;
      line-height: 1.5rem;
      margin-bottom: 10px;
    }

    svg {
      font-size: 1.85em;
      margin-right: 15px;
      vertical-align: -0.25em;
    }
  }

  .menu-link {
    transition: 0.3s opacity;
  }

  &.collapsed {
    width: 50px;

    .menu-link {
      opacity: 0;
    }
  }

  .icon-wrapper {
    display: inline-block;
  }

  .icon-badge {
    position: absolute;
    top: 0px;
    right: 8px;
  }
}
</style>
