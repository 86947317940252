import Vue from "vue";
import moment from "moment";
import StringMask from "string-mask";
import _ from "lodash";

const statusVariant = (status) => {
  let variant = "secondary";

  const _status = status?.toLowerCase();

  if (
    _status?.includes("cancelled") ||
    _status?.includes("reject") ||
    _status?.includes("decline")
  ) {
    variant = "danger";
  } else if (_status?.includes("answered") || _status?.includes("quotation")) {
    variant = "warning";
  } else if (_status?.includes("awaiting confirmation")) {
    variant = "purple";
  } else if (_status?.includes("waiting")) {
    variant = "primary";
  } else if (_status?.includes("confirmed")) {
    variant = "success";
  }

  return variant;
};

const dateRangeFormat = (sDate, eDate) => {
  let startDate = moment(sDate),
    endDate = moment(eDate);

  if (endDate.diff(startDate, "days") == 0) {
    if (startDate.diff(new Date(), "days") == 0) return "today";
    else return startDate.format("MM/DD/YYYY");
  } else {
    return `${startDate.format("MM/DD/YYYY")} to ${endDate.format(
      "MM/DD/YYYY"
    )}`;
  }
};

const monthDateRange = () => {
  let startDate = moment()
      .startOf("month")
      .toDate(),
    endDate = moment()
      .endOf("month")
      .toDate();
  return { startDate, endDate };
};

const phoneFormat = (phone) => {
  let formatter = new StringMask("(000) 000-0000");
  phone = stripPhoneFormat(phone);

  return formatter.apply(phone);
};

const stripPhoneFormat = (phone) => {
  if (phone) return phone.replace(/[^\d]+/g, "");
  else return phone;
};

const paymentMethodConversion = (p) => {
  if (p == "Credit Card") return "credit-card";
  else if (p == "Cash") return "cash";
  else if (p && p.indexOf("不用收錢") >= 0) return "no-pay";
  else return "";
};

const clipboardMsgFormatter = (order) => {
  let o = {
    time: order.time,
    locations: "",
    passenger_count: order.passenger_count,
    luggage_count: order.luggage_count,
    handcarry_count: order.handcarry_count,
    other_luggages: "",
    payment_method: order.payment_method,
    language: "",
    instruction: order.instruction,
    cost: "$" + order.cost,
    receipt: order.receipt ? `$${order.receipt}` : "",
    cost_407: order.cost_407 ? `$${order.cost_407}` : "",
    passenger: "",
  };
  if (_.isArray(order.other_luggages))
    o.other_luggages = order.other_luggages.join(", ");
  if (order.passenger && order.customer_isnot_passenger) {
    if (_.isArray(order.passenger) && order.passenger.length > 0) {
      o.passenger = "";

      order.passenger.forEach((p) => {
        if (p.name) o.passenger += p.name + " ";
      });

      o.passenger = _.trim(o.passenger);
    } else if (order.passenger.name) {
      o.passenger = order.passenger.name;
    }
  } else o.passenger = order.customer.name;
  if (_.isArray(order.locations)) {
    const locations = order.locations.map((l, idx) => {
      const _name = order.location_names?.[idx];
      const _waitTime = order.wait_times?.[idx - 1];
      const waitStr = _waitTime ? `@${_waitTime}mins` : "";
      const nameStr = _name ? `@${_name}` : "";
      return `${l}${nameStr}${waitStr}`;
    });
    o.locations = locations.join(" - ");
  }
  if (order.language) {
    if (order.language == "mandarin") o.language = "國";
    else if (order.language == "english") o.language = "英";
    else o.language = "";
  }
  if (order.on_behalf_cost) {
    o.on_behalf_cost = `$${order.on_behalf_cost} (代收) `;
  } else {
    o.on_behalf_cost = "";
  }

  // let msg = `2130pm  address1 - address2  { 1 P 1 L 1 HC other_luggages  payment_method  language  instruction  cost + receipt R + cost_407 (407) passenger_name|phone 坐車 }`;
  return `${o.time}  ${o.locations}  { ${o.passenger_count} P ${
    o.luggage_count
  } L ${o.handcarry_count} HC ${o.other_luggages}  ${o.payment_method}  ${
    o.language
  }  ${o.cost} + ${o.receipt} R + ${o.cost_407} (407) ${o.on_behalf_cost}${
    o.passenger
  } 坐車 } ${o.instruction ?? ""}`;
};

const statusHelpers = {
  statusVariant,
};

const dateHelpers = {
  dateRangeFormat,
  monthDateRange,
};

const stringHelpers = {
  phoneFormat,
  stripPhoneFormat,
  paymentMethodConversion,
  clipboardMsgFormatter,
};

const mapHelpers = {
  toKm(a1, a2) {
    var e = Math,
      ra = e.PI / 180;
    var b = a1.lat * ra,
      c = a2.lat * ra,
      d = b - c;
    var g = a1.lng * ra - a2.lng * ra;
    var f =
      2 *
      e.asin(
        e.sqrt(
          e.pow(e.sin(d / 2), 2) + e.cos(b) * e.cos(c) * e.pow(e.sin(g / 2), 2)
        )
      );
    return f * 6378.137;
  },
  inKm(points) {
    var a = points,
      len = points.length,
      dist = 0;
    for (var i = 0; i < len - 1; i++) {
      dist += mapHelpers.toKm(a[i], a[i + 1]);
    }
    return dist;
  },
};

const arrayHelpers = {
  emptyArray(length) {
    let emptyArray = [];

    for (var i = 0; i < length; i++) {
      emptyArray.push(null);
    }

    return emptyArray;
  },
  emptyColorArray(length) {
    let emptyArray = [];

    for (var i = 0; i < length; i++) {
      emptyArray.push("transparent");
    }

    return emptyArray;
  },
};

export default {
  install: () => {
    Vue.prototype.statusHelpers = statusHelpers;
    Vue.statusHelpers = statusHelpers;

    Vue.prototype.dateHelpers = dateHelpers;
    Vue.dateHelpers = dateHelpers;

    Vue.prototype.stringHelpers = stringHelpers;
    Vue.stringHelpers = stringHelpers;

    Vue.prototype.mapHelpers = mapHelpers;
    Vue.mapHelpers = mapHelpers;

    Vue.prototype.arrayHelpers = arrayHelpers;
    Vue.arrayHelpers = arrayHelpers;
  },
};
