import router from "../../router";
import api from "../../api/index";

// initial state
const state = () => ({
  userList: [ ],
  userProfile: null,
  userId: null,
  loginError: null,
});

// getters
const getters = {};

// actions
const actions = {
  async fetchUserList ({ commit }) {
    const usersSnapShot = await api.user.getUsers();
    const _users = [ ];

    usersSnapShot.forEach((doc) => {
      const _user = doc.data();
      _user["userId"] = doc.id;
      _users.push(_user);
    });

    commit("setUserList", _users || [ ]);
  },
  async fetchUserProfile({ commit, dispatch }, user) {
    // fetch user profile
    const userProfile = await api.user.getUser(user.uid);

    let profileData = userProfile.data();
    if (!profileData) {
      await dispatch('newUserProfile', user);
    }

    // set user profile in state
    commit("setUserProfile", profileData);
    commit("setUserId", user.uid);

    // change route to dashboard
    if (router.currentRoute.path === "/login") router.push("/");
  },
  async updateUserProfile ({ commit }, { userId, userProfile }) {
    if (userId) {
      await api.user.setUser(userId, userProfile);
    }
  },
  async newUserProfile ({ commit }, user) {
    if (user && user.email) {
      await api.user.setUser(user.uid, {
        name: user.email.split('@')[0],
        email: user.email,
      });
    }
  },
  async login({ dispatch, commit }, form) {
    // sign user in
    try {
      const { user } = await api.auth.logIn(form.email, form.password);

      // fetch user profile and set in state
      dispatch("fetchUserProfile", user);
    } catch (e) {
      commit("setLoginError", e);
    }
  },
  async signup({ dispatch }, form) {
    const { user } = await api.auth.signUp(form.email, form.password);

    // create user profile object in userCollections
    await dispatch("newUserProfile", user);

    // fetch user profile and set in state
    dispatch("fetchUserProfile", user);
  },
  async logout({ commit }) {
    await api.auth.signOut();

    // clear userProfile and redirect to /login
    commit("setUserProfile", null);
    commit("setUserId", null);
    router.push("/login");
  },
};

// mutations
const mutations = {
  setUserProfile(state, val) {
    state.userProfile = val;
  },
  setLoginError(state, val) {
    state.loginError = val;
  },
  setUserId (state, val) {
    state.userId = val;
  },
  setUserList (state, val) {
    state.userList = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
