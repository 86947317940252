import {
  setDoc,
  doc,
  getFirestore,
  deleteDoc,
  addDoc,
  getDoc,
  getDocs,
  collection,
  where,
} from "firebase/firestore";

const COLLECT = "locations";
const CATCOLLECT = "locationCategory";

export default {
  getLocationCategories() {
    const db = getFirestore();
    return collection(db, CATCOLLECT);
  },
  getLocation(id) {
    const db = getFirestore();
    return getDoc(doc(db, COLLECT, id));
  },
  getLocationByCategory(category) {
    const db = getFirestore();
    return getDocs(collection(db, COLLECT), where("category", "==", category));
  },
  setLocation(id, data) {
    const db = getFirestore();
    if (id != null) {
      return setDoc(doc(db, COLLECT, id), data, { merge: true });
    }
    return addDoc(collection(db, COLLECT), data);
  },
  setLocationCategory(id, data) {
    const db = getFirestore();
    if (id != null) {
      return setDoc(doc(db, CATCOLLECT, id), data, { merge: true });
    }
    return addDoc(collection(db, CATCOLLECT), data);
  },
  removeLocationCategory(id) {
    const db = getFirestore();
    deleteDoc(doc(db, CATCOLLECT, id));
  },
  removeLocation(id) {
    const db = getFirestore();
    deleteDoc(doc(db, COLLECT, id));
  },
};
