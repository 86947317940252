<template>
  <div id="app">
    <b-container fluid>
      <b-row class="flex-nowrap">
        <b-col v-if="$route.path != '/login'" cols="auto" class="hide-print">
          <Navigation
            @collapsed="menuCollapsed = true"
            @expanded="menuCollapsed = false"
          />
        </b-col>

        <b-col id="main-col" :class="{ 'menu-collapsed': menuCollapsed }">
          <router-view />
        </b-col>
      </b-row>
    </b-container>

    <notifications position="top center" />

    <b-modal
      no-fade
      v-model="idleModal"
      :hide-header="true"
      :hide-footer="true"
      :centered="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <p class="text-center my-4">
        <b-button @click="refreshPage">Click to reload page.</b-button>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navigation from "@/components/Navigation";
import IdleJs from "idle-js";
import { throttle } from "lodash";
import moment from "moment";

export default {
  name: "App",
  data() {
    return {
      showUpdateUI: false,
      menuCollapsed: localStorage.menuCollapsed === "true",
      idleModal: false,
      idleDuration: 10 * 60 * 1000,
    };
  },
  components: {
    Navigation,
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
      userEmail: (state) => {
        return state.user ? state.user.userProfile?.email : "";
      },
    }),
  },
  watch: {
    userId(nv) {
      if (nv) {
        this.$store.dispatch("settings/get");
        this.$store.dispatch("drivers/get");
        this.$store.dispatch("locations/getLocationCategory");
      }
    },
  },
  methods: {
    async accept() {
      this.showUpdateUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    refreshPage() {
      location.reload();
    },
    disMissModal() {
      if (!this.pageIdled) this.idleModal = false;
    },
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }

    this.$store.dispatch("user/fetchUserList");
  },
  mounted() {
    console.log("mounting");

    let idle = new IdleJs({
      idle: this.idleDuration, // idle time in ms
      events: ["mousemove", "keydown", "mousedown", "touchstart"],
      onIdle: () => {
        console.log("idling");
        this.idleModal = true;
      },
      onHide: function() {
        console.log("hiding");
      }, // callback function to be executed when window become hidden
      onShow: function() {
        console.log("showing");
      }, // callback function to be executed when window become visible
    });
    idle.start();

    let lastActivityTimer = throttle(() => {
      try {
        if (this.userId) {
          let dtFormat = "DD.MM.YYYY HH:mm:ss";
          let lastActivityTime = localStorage.getItem("lastActivityTime");
          let now = moment();

          if (lastActivityTime) {
            lastActivityTime = new moment(lastActivityTime, dtFormat);

            if (
              Math.abs(lastActivityTime.diff(now, "minutes")) > 45 &&
              ["stephen.slchiu@gmail.com", "ivanchaukn@gmail.com"].indexOf(
                this.userEmail
              ) < 0
            ) {
              localStorage.removeItem("lastActivityTime");
              this.$store.dispatch("user/logout");
              return;
            }
          }

          localStorage.setItem("lastActivityTime", now.format(dtFormat));
        }
      } catch {
        localStorage.removeItem("lastActivityTime");
      }
    }, 4000);
    ["mousemove", "keydown", "mousedown", "touchstart"].forEach((ev) => {
      window.addEventListener(ev, lastActivityTimer);
    });
  },
  beforeUnmount() {
    console.log("unmounting");
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
  &,
  > .container-fluid,
  > .container-fluid > .row {
    height: 100%;
  }
}

#main-col {
  height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
