import Vue from "vue";
import VueRouter from "vue-router";

import { getAuth } from "firebase/auth";
import OrdersPage from "../views/OrdersPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/orders",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders",
    name: "Order Listing",
    component: OrdersPage,
    props: { id: 'order', fromMobile: false },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mobile",
    name: "Mobile Order",
    component: OrdersPage,
    props: { id: 'mobile', fromMobile: true },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/order",
    name: "Input Order",
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/InputPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customers",
    name: "Customer Listing",
    component: () =>
      import(/* webpackChunkName: "customers" */ "../views/CustomersPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/drivers",
    name: "Driver Listing",
    component: () =>
      import(/* webpackChunkName: "drivers" */ "../views/DriversPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/SettingsPage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  let user = getAuth().currentUser;

  if (requiresAuth && !user) {
    next("/login");
  } else if (to.name == 'Driver Listing' && ([ 'stephen.slchiu@gmail.com', 'ivanchaukn@gmail.com', 'ivanlau718@hotmail.com' ].indexOf(user.email) == -1)) { // Only allow admin to access driver page
    next("/orders");
  } else {
    next();
  }
});

export default router;
