<template>
  <b-dropdown
    :variant="statusHelpers.statusVariant(dropdown_status)"
    :text="$_.upperFirst(dropdown_status) || 'Choose Status'"
    :size="size || ''"
    :disabled="this.disabled"
    :no-caret="this.disabled"
  >
    <b-dropdown-item
      @click.prevent="selectItem(status)"
      v-for="status in statuses"
      :key="`status-${status}`"
    >
      {{ $_.upperFirst(status) }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "StatusDropdown",
  props: ["value", "size", "disabled"],
  computed: {
    ...mapState({
      statuses: function (state) {
        // console.log(this.dropdown_status);
        if (location.pathname == '/mobile') {
          if ([ 'answered', 'awaiting reply' ].indexOf(this.dropdown_status.toLowerCase()) >= 0)
            return [ "Order Rejected", ];
          else
            return [ "Order Rejected", "Confirmed" ];
        } else {
          return state.settings.settings.status;
        }
      },
    }),
  },
  data() {
    return {
      dropdown_status: this.value,
    };
  },
  watch: {
    value(nv) {
      this.dropdown_status = nv;
    },
  },
  methods: {
    selectItem(status) {
      this.dropdown_status = status;
      this.$emit("input", this.dropdown_status);
      this.$emit("change", this.dropdown_status);
    },
  },
};
</script>

<style lang="scss"></style>
