import firebase from "firebase/compat/app";
import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore";

const COLLECT = "users";

export default {
  getUsers () {
    const db = firebase.firestore();
    let q = db.collection(COLLECT);

    return q.get();
  },
  getUser(uid) {
    const db = getFirestore();
    return getDoc(doc(db, COLLECT, uid));
  },
  setUser(uid, data) {
    // create user profile object in userCollections
    const db = getFirestore();
    return setDoc(doc(db, COLLECT, uid), data);
  },
};
