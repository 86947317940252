import {
  doc,
  where,
  query,
  limit,
  orderBy,
  setDoc,
  getDoc,
  addDoc,
  deleteDoc,
  collection,
  getFirestore,
  startAfter,
} from "firebase/firestore";

const COLLECT = "orders";
const TOTALCOLLECT = "orderTotal";

export default {
  /**
   * @param {object} options
   * @param {object} options.filters - Order filter
   * @param {string} options.filters.customerId - Filter by specific customer
   * @param {date} options.filters.startDate - Show order of date range
   * @param {date} options.filters.endDate - Show order of date range
   * @param {string} options.filters.driverId
   * * @param {string} options.filters.statusSimple
   * @param {int} [options.count]
   * @param {dateTime} [options.startAfter]
   * @param {string} [options.orderby=date] - Possible options: date, driver, customer
   * @param {string} [options.order=ASC] - [ ASC, DESC ]
   * @param {string} [options.fromMobile=false] - [ false, true ]
   */
  getOrders(options) {
    const db = getFirestore();
    let q = collection(db, COLLECT);

    if (options?.filters) {
      const filters = options?.filters;
      if (filters?.customerId) {
        q = query(q, where("customerId", "==", filters?.customerId));
      }
      if (filters?.payment_method) {
        q = query(q, where("payment_method", "==", filters?.payment_method));
      }
      if (filters?.driverId) {
        q = query(q, where("driverId", "==", filters?.driverId));
      }
      if (filters?.status) {
        q = query(q, where("status", "==", filters?.status));
      }
      if (filters?.statuses) {
        q = query(q, where("status", "in", filters?.statuses));
      }
      if (filters?.statusSimple) {
        q = query(q, where("statusSimple", "==", filters?.statusSimple));
      }

      if (filters?.startDate) {
        q = query(q, where("dateTime", ">=", filters?.startDate));
      }
      if (filters?.endDate) {
        q = query(q, where("dateTime", "<=", filters?.endDate));
      }

      if (filters?.createdAt) {
        q = query(q, where("createdAt", "<=", filters?.createdAt));
      }
      if (filters?.updatedAt) {
        q = query(q, where("updatedAt", "<=", filters?.updatedAt));
      }
    }

    if (options?.count) {
      q = query(q, limit(options?.count));
    }

    const fromMobile = options?.fromMobile;
    const mobileOnly = options?.mobileOnly;

    if (fromMobile !== null && fromMobile !== undefined) {
      const isMobile = fromMobile || mobileOnly;

      if (isMobile) {
        const mobileWhere = where("fromMobile", "==", isMobile);
        q = query(q, mobileWhere);
      }
    }

    const _orderBy = options?.orderBy || "dateTime";
    const _order = options?.order || "asc";
    q = query(q, orderBy(_orderBy, _order));

    if (options?.startAfter) {
      q = query(q, startAfter(options?.startAfter));
    }

    return q;
  },
  getMonthlyOrdersTotal() {
    const db = getFirestore();
    return getDoc(doc(db, TOTALCOLLECT, "monthlyTotal"));
  },
  getDailyOrdersTotal() {
    const db = getFirestore();
    return getDoc(doc(db, TOTALCOLLECT, "dailyTotal"));
  },
  setOrder(orderId, data) {
    const db = getFirestore();

    if (orderId == null) {
      return addDoc(collection(db, COLLECT), data);
    }
    return setDoc(doc(db, COLLECT, orderId), data, { merge: true });
  },
  deleteOrder(orderId) {
    const db = getFirestore();
    return deleteDoc(doc(db, COLLECT, orderId));
  },
};
