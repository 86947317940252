import {
  getDocs,
  setDoc,
  doc,
  query,
  collection,
  getFirestore,
  deleteDoc,
  addDoc,
} from "firebase/firestore";

const COLLECT = "drivers";

export default {
  getDrivers() {
    const db = getFirestore();
    const q = query(collection(db, COLLECT));
    return q;
  },
  setDriver(driverId, data) {
    const db = getFirestore();

    if (driverId != null) {
      return setDoc(doc(db, COLLECT, driverId), data, { merge: true });
    }
    return addDoc(collection(db, COLLECT), data);
  },
  deleteDriver(driverId) {
    const db = getFirestore();
    const q = doc(db, COLLECT, driverId);
    deleteDoc(q);
  },
};
