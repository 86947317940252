import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import orders from "./modules/orders";
import drivers from "./modules/drivers";
import customers from "./modules/customers";
import settings from "./modules/settings";
import locations from "./modules/locations";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  strict: debug,
  modules: {
    user,
    customers,
    orders,
    drivers,
    settings,
    locations,
  },
});
