import api from "../../api/index";
import { onSnapshot } from "firebase/firestore";

// initial state
const state = () => ({
  locations: {},
  categoryLocations: {},
  categories: {},
  selectedLocation: {},
});

// getters
const getters = {};

// actions
const actions = {
  async getLocationCategory({ commit }) {
    onSnapshot(api.locations.getLocationCategories(), (querySnapshot) => {
      const _categories = {};
      querySnapshot.forEach((doc) => {
        const _category = doc.data();
        _category["categoryId"] = doc.id;
        _categories[doc.id] = _category;
      });
      commit("setCategory", _categories);
    });
  },

  async getLocation({ commit }, locationId) {
    const docSnap = await api.locations.getLocation(locationId);
    const _location = docSnap.data();
    _location["locationId"] = docSnap.id;
    commit("setLocation", _location);
  },

  async getLocationByCategory({ commit }, category) {
    const querySnapshot = await api.locations.getLocationByCategory(category);
    const _locations = {};
    querySnapshot.forEach((doc) => {
      const _location = doc.data();
      _location["locationId"] = doc.id;
      _locations[doc.id] = _location;
    });
    commit("setCategoryLocation", _locations);
  },

  async setLocation(context, { locationId, data }) {
    await api.locations.setLocation(locationId, data);
  },

  async removeLocation(context, locationId) {
    await api.locations.removeLocation(locationId);
  },

  async setLocationCategory(context, { categoryId, name }) {
    await api.locations.setLocationCategory(categoryId, { name });
  },

  async removeLocationCategory(context, categoryId) {
    await api.locations.removeLocationCategory(categoryId);
  },
};

// mutations
const mutations = {
  setCategory(state, val) {
    state.categories = val;
  },
  setLocation(state, val) {
    state.selectedLocation = val;
  },
  setCategoryLocation(state, val) {
    state.categoryLocations = val;
  },
  addTag(state, val) {
    state.selectedLocation.tags.push(val);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
