import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

export default {
  logIn(email, password) {
    const auth = getAuth();
    try {
      return signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      console.log(e);
    }
  },
  signOut() {
    const auth = getAuth();
    try {
      return signOut(auth);
    } catch (e) {
      console.log(e);
    }
  },
  signUp(email, password) {
    const auth = getAuth();
    try {
      return createUserWithEmailAndPassword(auth, email, password);
    } catch (e) {
      console.log(e);
    }
  },
  resetPassword(email) {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email);
  },
};
