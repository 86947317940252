<template>
  <div class="row" id="input-row">
    <div class="col">
      <div :key="id" class="box full-box">
        <OrderForm
          :showOrderForm="true"
          :customer="selectedOrder.customer"
          :passInOrder="selectedOrder"
          @orderSubmitted="hideOrder"
          v-if="showOrderForm"
        >
          <template v-slot:beforeForm>
            <b-button size="sm" variant="outline-primary" @click="hideOrder">
              <b-icon style="font-size: .9em;" icon="arrow-left"></b-icon>
              Back
            </b-button>
            <b-button
              class="flex-grow-1 ml-2"
              variant="primary"
              @click="getCustomerOrders(selectedOrder)"
              size="sm"
              >過去記錄
            </b-button>
          </template>
        </OrderForm>

        <b-row class="mb-2 hide-print" v-if="!showOrderForm">
          <b-col cols="auto" class="form-inline">
            <DateRangeOptions
              v-model="dateRange"
              :defaultRange="dateRange.endDate ? 'Date Range' : 'Current'"
              class="mr-3"
              @optionChanged="dateRangeOptionChanged"
            />

            <!-- <b-form-checkbox
          size="sm"
          id="input-show-notice"
          v-model="hideNotice"
        >
          只顯示還沒有發送的訂單
        </b-form-checkbox> -->
          </b-col>
          <b-col cols="auto" class="ml-auto">
            <b-row>
              <b-form-checkbox
                size="sm"
                class="mr-2 mt-1"
                v-model="mobileOnly"
                name="mobile-only"
              >
                Mobile
              </b-form-checkbox>

              <span
                class="btn btn-light mr-2 btn-sm"
                variant="light"
                v-if="showTotal"
              >
                <span>總額 ${{ rangeTotal }}</span>
              </span>

              <b-form-select
                class="mr-2"
                v-model="paymentMethod"
                size="sm"
                v-if="!this.fromMobile"
              >
                <b-form-select-option
                  v-for="(paymentMethod, pm_index) in [
                    'All',
                    ...settings.payment_methods,
                  ]"
                  :key="`paymentMethod-${pm_index}`"
                  :value="paymentMethod"
                >
                  {{ paymentMethod }}
                </b-form-select-option>
              </b-form-select>

              <ul
                role="menubar"
                aria-disabled="false"
                aria-label="Pagination"
                class="pagination b-pagination pagination-sm"
              >
                <li role="presentation" aria-hidden="true" class="page-item">
                  <button
                    :disabled="this.orderLoading"
                    role="menuitem"
                    type="button"
                    @click="loadPreviousOrders"
                    class="page-link"
                  >
                    «
                  </button>
                </li>
                <li
                  role="separator"
                  class="page-item disabled bv-d-xs-down-none"
                >
                  <span class="page-link" v-if="orderLoading">
                    <b-spinner label="Loading..." small></b-spinner>
                  </span>
                </li>
                <li
                  role="presentation"
                  class="page-item"
                  :class="{ disabled: !this.orderItems.length > 0 }"
                >
                  <button
                    :disabled="!this.orderItems.length > 0 || this.orderLoading"
                    role="menuitem"
                    type="button"
                    @click="loadNextOrders"
                    class="page-link"
                  >
                    »
                  </button>
                </li>
              </ul>

              <b-button
                @click="print"
                variant="light"
                size="sm"
                class="ml-2 mr-2"
              >
                <b-icon-printer />
              </b-button>
            </b-row>
          </b-col>
        </b-row>

        <div id="table-wrapper" v-show="!showOrderForm">
          <b-table
            :busy="orderLoading"
            ref="orderTable"
            sticky-header
            :items="orderItems"
            :fields="this.orderFields"
            head-variant="light"
            :no-border-collapse="false"
            :tbody-tr-class="rowClass"
            responsive
            :show-empty="true"
            :class="{
              'empty-table': $_.isEmpty(orderItems),
            }"
          >
            <template #empty>
              <div class="box outline-box p-4">
                <p class="text-center m-0 h6">No order anymore</p>
              </div>
            </template>
            <template #cell(driver)="row">
              <div
                class="form-inline flex-nowrap align-items-start"
                :style="{
                  'background-color': row.item.color
                    ? row.item.color.hex || row.item.color
                    : 'transparent',
                }"
              >
                <div class="d-flex align-items-start">
                  <input
                    v-model="row.item.rank"
                    class="input-order mr-1 form-control form-control-xs hide-print"
                    placeholder="00"
                    @blur="updateRank(row.item, row.index)"
                  />
                  <span class="show-print-inline">{{ row.item.rank }}</span>

                  <div style="width: 80px;" class="text-right hide-print">
                    <v-select
                      label="text"
                      :appendToBody="true"
                      :clearable="false"
                      :taggable="true"
                      :options="drivers"
                      :reduce="(d) => d.value"
                      :value="
                        $_.find(drivers, (d) => d.value == row.item.driverId)
                          ? row.item.driverId
                          : ''
                      "
                      @option:created="createDriver($event, row.item)"
                      @option:selected="selectDriver($event, row.item)"
                    >
                      <template #open-indicator>
                        <b-icon icon="chevron-down"></b-icon>
                      </template>
                    </v-select>

                    <a
                      href="#"
                      v-if="
                        $_.find(drivers, (d) => d.value == row.item.driverId)
                      "
                      @click.prevent="clearDriver(row.item)"
                      class="clear-driver"
                      >clear</a
                    >
                  </div>

                  <span class="show-print-inline">{{
                    $_.find(drivers, (d) => d.value == row.item.driverId)
                      ? $_.find(drivers, (d) => d.value == row.item.driverId)
                          .text
                      : ""
                  }}</span>

                  <a
                    href="#"
                    @click.prevent
                    class="color-pick no-underline"
                    style="top: 13px;"
                  >
                    <b-icon
                      v-if="row.item.color == 'transparent'"
                      @click="toggleColorPicker(row)"
                      icon="droplet-fill"
                    ></b-icon>
                    <b-icon
                      v-else
                      icon="x"
                      @click="removeColor(row.item)"
                      style="transform: scale(1.5);"
                    ></b-icon>
                  </a>
                </div>

                <p
                  class="hide-print createdBy mb-0 font-italic"
                  v-if="row.item.createdBy || row.item.statusChangedBy || row.item.updatedBy"
                >
                  <strong>Created by:</strong>
                  {{
                    row.item.fromMobile
                      ? row.item.statusChangedBy || row.item.updatedBy
                      : row.item.createdBy
                  }}
                </p>

                <p class="d-none">Created: {{ row.item.createdBy }}</p>
                <p class="d-none">Updated: {{ row.item.updatedBy }}</p>
                <p class="d-none">
                  StatusChanged: {{ row.item.statusChangedBy }}
                </p>
              </div>
            </template>

            <template #cell(status)="data" :fromMobile="this.fromMobile">
              <StatusDropdown
                v-model="data.item.status"
                @change="setOrder(data.item)"
                size="xs"
                :disabled="fromMobile"
              />
            </template>

            <template #cell(dateTime)="data">
              <span class="text-nowrap"
                >{{
                  moment
                    .unix(data.item.dateTime.seconds)
                    .format("MMM DD, YYYY")
                }}<br />{{
                  moment.unix(data.item.dateTime.seconds).format("HH:mm")
                }}</span
              >
            </template>

            <template #cell(locations)="data">
              <div
                :style="{
                  'background-color': data.item.location_colors[ad_index]
                    ? data.item.location_colors[ad_index].hex ||
                      data.item.location_colors[ad_index]
                    : 'transparent',
                }"
                :class="{
                  'position-relative': true,
                  'address-row': true,
                  last: ad_index == data.item.locations.length - 1,
                }"
                v-for="(ad, ad_index) in data.item.locations"
                :key="`add-row-${data.index}-${ad_index}`"
              >
                <span class="d-inline-block"
                  >{{ ad }}
                  <div
                    v-if="
                      data.item.location_names != null &&
                        !$_.isEmpty(data.item.location_names[ad_index]) &&
                        ad_index < data.item.location_names.length
                    "
                  >
                    | {{ data.item.location_names[ad_index] }}
                  </div>
                  <div
                    v-if="
                      data.item.wait_times != null &&
                        ad_index - 1 < data.item.wait_times.length &&
                        data.item.wait_times[ad_index - 1]
                    "
                  >
                    | {{ data.item.wait_times[ad_index - 1] }} mins
                  </div>
                </span>

                <a href="#" @click.prevent class="no-underline color-pick">
                  <b-icon
                    v-if="data.item.location_colors[ad_index] == 'transparent'"
                    icon="droplet-fill"
                    @click="
                      toggleColorPicker(data, `location_colors[${ad_index}]`)
                    "
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="x"
                    @click="
                      removeColor(data.item, `location_colors[${ad_index}]`)
                    "
                    style="transform: scale(1.5);"
                  ></b-icon>
                </a>
              </div>
            </template>

            <template #cell(information)="row">
              <OrderMeta :order="row.item" />
            </template>

            <template #cell(instruction)="row">
              <div
                class="driver_instruction_cell mb-4"
                v-if="!$_.isEmpty(row.item.instruction)"
                v-html="row.item.instruction.replace(/(?:\r\n|\r|\n)/g, '<br>')"
              ></div>

              <div class="driver_instruction_cell mb-4" v-if="row.item.arrived">
                {{
                  `乘客到達 ${moment(row.item.arrivedAt.toDate()).format(
                    "hh:MM:ss A"
                  )}`
                }}
              </div>

              <a class="no-underline color-pick" href="#" @click.prevent>
                <b-icon
                  v-if="row.item.instruction_color == 'transparent'"
                  @click="toggleColorPicker(row, 'instruction_color')"
                  icon="droplet-fill"
                ></b-icon>
                <b-icon
                  v-else
                  icon="x"
                  @click="removeColor(row.item, 'instruction_color')"
                  style="transform: scale(1.5);"
                ></b-icon>
              </a>
            </template>

            <template #cell(remark)="row">
              <p
                v-if="row.item.remark"
                v-html="row.item.remark.replace(/(?:\r\n|\r|\n)/g, '<br>')"
              ></p>

              <span>
                <template v-if="row.item.businessName">
                  企業賬戶 - {{ row.item.businessName }}<br />
                </template>
                {{ row.item.businessPhone }}
              </span>

              <a class="no-underline color-pick" href="#" @click.prevent>
                <b-icon
                  v-if="row.item.remark_color == 'transparent'"
                  @click="toggleColorPicker(row, 'remark_color')"
                  icon="droplet-fill"
                ></b-icon>
                <b-icon
                  v-else
                  icon="x"
                  @click="removeColor(row.item, 'remark_color')"
                  style="transform: scale(1.5);"
                ></b-icon>
              </a>
            </template>

            <template #cell(commonQ)="row">
              <p
                v-if="row.item.commonQuestions"
                v-html="
                  row.item.commonQuestions.replace(/(?:\r\n|\r|\n)/g, '<br>')
                "
              ></p>
            </template>

            <template #cell(special)="row">
              <p
                v-if="row.item.specialRequests"
                v-html="
                  row.item.specialRequests.replace(/(?:\r\n|\r|\n)/g, '<br>')
                "
              ></p>
            </template>

            <template #cell(customer_passenger)="data">
              <div style="width: 110px;">
                <div v-if="data.item.customer">
                  <span class="cus-pas-label"
                    >訂車人
                    <b-icon
                      v-if="
                        $_.isArray(data.item.customer.otherPhones) &&
                          data.item.customer.otherPhones.length > 0
                      "
                      v-b-popover.hover.left="{
                        content: otherPhonesContent(data.item),
                        html: true,
                      }"
                      class="float-right hide-print"
                      icon="telephone-outbound-fill"
                    ></b-icon>
                  </span>
                  <span>
                    <template v-if="data.item.customer.name"
                      >{{ data.item.customer.name }}<br /></template
                    >{{ data.item.customer.phone }}
                  </span>
                </div>

                <div
                  class="mt-2"
                  v-if="
                    data.item.passenger &&
                      $_.every(data.item.passenger, (op) => !$_.isEmpty(op)) &&
                      data.item.customer_isnot_passenger
                  "
                >
                  <span class="cus-pas-label">乘客</span>

                  <template v-if="$_.isArray(data.item.passenger)">
                    <div
                      v-for="(p, p_index) in data.item.passenger"
                      :key="`passenger_list_${data.item.orderId}_${p_index}`"
                    >
                      <template v-if="p.name">{{ p.name }}<br /></template>
                      {{ p.phone }}
                      <template v-if="p.remark"><br />{{ p.remark }}</template>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="data.item.passenger.name"
                      >{{ data.item.passenger.name }}<br
                    /></template>
                    {{ data.item.passenger.phone }}
                    <template v-if="data.item.passenger.remark"
                      ><br />{{ data.item.passenger.remark }}</template
                    >
                  </template>
                </div>
              </div>
            </template>

            <template #cell(business)="data">
              <div style="width: 110px;">
                <div v-if="data.item.customer">
                  <span>
                    <template v-if="data.item.businessName"
                      >{{ data.item.businessName }}<br /></template
                    >{{ data.item.businessPhone }}
                  </span>
                </div>
              </div>
            </template>

            <template #cell(notice)="row">
              <b-button-group>
                <b-button
                  v-if="!row.fromMobile"
                  :pressed="row.item.notice"
                  @click.prevent="toggleNotice(row.item)"
                >
                  <b-icon icon="exclamation"></b-icon>
                </b-button>
              </b-button-group>
            </template>

            <template #cell(edit)="row" :fromMobile="this.fromMobile">
              <b-button-group
                ><b-button @click="showOrder(row.item)"
                  ><b-icon icon="pencil"></b-icon
                ></b-button>
              </b-button-group>
            </template>

            <template #cell(copy)="row">
              <b-button-group>
                <b-button
                  class="clip"
                  :data-clipboard-text="rowCopyClipboard(row.item)"
                  @click="clipButtonClick(row.item)"
                  ><b-icon icon="chat-dots"></b-icon
                ></b-button>
              </b-button-group>
            </template>

            <template #cell(delete)="row">
              <b-button-group>
                <b-button @click.prevent="unsetOrder(row.item)"
                  ><b-icon icon="trash"></b-icon
                ></b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>

        <div style="position: absolute; z-index: 1040;" v-if="showColorSlider">
          <div class="modal fade show" style="display: block;">
            <div class="modal-dialog modal-dialog-centered">
              <div class="slider-picker">
                <twitter-picker
                  :defaultColors="[
                    '#999999',
                    '#9900EF',
                    '#F44E3B',
                    '#FE9200',
                    '#FCDC00',
                    '#DBDF00',
                    '#A4DD00',
                    '#68CCCA',
                    '#73D8FF',
                    '#AEA1FF',
                    '#FDA1FF',
                    '#CCCCCC',
                    '#D33115',
                    '#E27300',
                  ]"
                  triangle="hide"
                  v-model="myColor"
                  class="mb-3"
                >
                </twitter-picker>

                <p class="text-right mb-0">
                  <b-button
                    variant="primary"
                    class="mr-2"
                    size="sm"
                    @click="colorSelected"
                    v-if="myColor != 'transparent'"
                    >Use Color
                  </b-button>
                  <b-button class="mr-2" size="sm" @click="hideColorPickers"
                    >Cancel</b-button
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="modal-backdrop"></div>
        </div>
      </div>
    </div>

    <transition name="slide-width" :duration="300">
      <div class="col-auto pl-0" id="recent-orders-col" v-if="showRecentOrders">
        <div id="recent-orders" class="box gray-box full-box">
          <b-button
            class="float-right"
            variant="outline-primary"
            size="sm"
            id="btn-toggleRecentOrder"
            @click="hideRecentOrders"
          >
            <b-icon icon="arrow-bar-right"></b-icon>
          </b-button>
          <h2 class="box-title">最近記錄 / Recent Order</h2>

          <template v-if="!$_.isEmpty(recentOrders)">
            <RecentOrder
              @useOrder="useRecentOrder($event)"
              v-for="(o, index) in recentOrders"
              :key="`ri-${index}`"
              :order="o"
            >
            </RecentOrder>
          </template>
          <p v-else>沒有過去的訂單</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps(["fromMobile"]);
</script>

<script>
// import OrderModal from "../components/OrderModal.vue";
import OrderForm from "../components/OrderForm.vue";
import OrderMeta from "../components/OrderMeta.vue";
import DateRangeOptions from "../components/DateRangeOptions.vue";
import StatusDropdown from "../components/StatusDropdown.vue";
import RecentOrder from "../components/RecentOrder.vue";

import { Twitter } from "vue-color";
import MaskedInput from "vue-masked-input";
import ClipboardJS from "clipboard";
import vSelect from "vue-select";

import { mapState, mapActions } from "vuex";
import Moment from "moment";
import { extendMoment } from "moment-range";
import $ from "jquery";
import {
  map,
  filter,
  cloneDeep,
  pick,
  forEach,
  isUndefined,
  isBoolean,
  sortBy,
  find,
  isString,
  isArray,
  set,
  debounce,
  remove,
  omit,
} from "lodash";

const moment = extendMoment(Moment);

export default {
  name: "OrderPage",
  props: ["id", "fromMobile"],
  components: {
    DateRangeOptions,
    OrderForm,
    // OrderModal,
    OrderMeta,
    StatusDropdown,
    // MaskedInput,
    RecentOrder,
    "twitter-picker": Twitter,
    vSelect,
  },
  data() {
    return {
      perPage: 100,
      toOrderReverse: false,
      orderReverse: false,
      orderLoading: false,
      paymentMethod: "All",
      mobileOnly: false,
      showRecentOrders: false,
      showColorSlider: false,
      myColor: "transparent",
      selectedRowForColorSlider: null,
      selectedKeyForColorSlider: null,
      showOrderForm: false,
      // showRecentOrders: false,
      selectedOrder: {},

      dateRange: {
        startDate: moment()
          .subtract(90, "minutes")
          .toDate(),
      },
    };
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
      currentUser: (state) => state.user.userProfile,
      regular_orders: (state) => state.orders.orders,
      mobile_orders: (state) => state.orders.mobileOrders,
      recentOrders: (state) => state.orders.recentOrders,
      drivers: (state) => {
        return sortBy(
          map(state.drivers.drivers, (d) => {
            return {
              value: d.driverId,
              text: d.name,
            };
          }),
          (d) => {
            if (isString(d.text)) return d.text.toLowerCase();
            else return d.text;
          }
        );
      },
      monthlyTotal: (state) => state.orders.monthlyTotal,
      dailyTotal: (state) => state.orders.dailyTotal,
    }),
    orderItems() {
      let orderItems =
        (this.fromMobile ? this.mobile_orders : this.regular_orders) ?? [];
      /*if (this.hideNotice) {
           orderItems = filter(orderItems, o => {
            return !o.notice;
          });
        }*/
      orderItems = cloneDeep(orderItems);
      if (this.orderReverse) orderItems.reverse();

      orderItems?.forEach((my_order) => {
        if (!my_order.driver) my_order.driver = "";
        if (!my_order.color) my_order.color = "transparent";
        if (!my_order.address_color) my_order.address_color = "transparent";
        if (!isArray(my_order.location_colors)) {
          /*let emptyLocationColors = [ ];
            for (var i = 0; i < my_order.locations.length; i++) {
              emptyLocationColors[i] = 'transparent';
            }*/
          my_order.location_colors = this.arrayHelpers.emptyColorArray(
            my_order.locations.length
          );
        }
        if (!my_order.instruction_color)
          my_order.instruction_color = "transparent";
        if (!my_order.remark_color) my_order.remark_color = "transparent";
        if (!my_order.rank) my_order.rank = "";
        if (!my_order.notice) my_order.notice = false;
      });

      return orderItems;
    },
    isMonthlyRange() {
      let nv = this.dateRange;

      if (nv.startDate && nv.endDate) {
        // If it is a date range, check to see if it is a monthly range
        let sd = moment(nv.startDate).startOf("month");
        let ed = moment(nv.endDate).endOf("month");
        if (sd.isSame(nv.startDate) && ed.isSame(nv.endDate)) {
          return true;
        }
      }
      return false;
    },
    showTotal() {
      let nv = this.dateRange;
      return nv.endDate != null;
    },
    rangeTotal() {
      if (this.isMonthlyRange) {
        let months = [];
        let range = moment.range(
          this.dateRange.startDate,
          this.dateRange.endDate
        );
        for (let month of range.by("month")) {
          months.push(month.format("YYYY-M"));
        }

        if (months.length) {
          let t = 0;
          forEach(pick(this.monthlyTotal, months), (totals) => {
            t += totals.cost + totals.no_pay_cost;
          });
          return t;
        }
      } else if (this.dateRange.endDate) {
        const _dtKeys = [];
        const _totals = {};

        Object.values(this.dailyTotal).forEach((monthVal) => {
          Object.keys(monthVal).forEach((dt) => {
            const _dtFormat = moment(dt).format("YYYY-MM-DD");
            _totals[_dtFormat] = monthVal[dt];
            _dtKeys.push(_dtFormat);
          });
        });

        _dtKeys.sort();

        let _dtRange = [..._dtKeys];
        if (this.dateRange.startDate) {
          const _sDt = moment(this.dateRange.startDate).format("YYYY-MM-DD");
          const _idx = _dtRange.indexOf(_sDt);
          _dtRange.splice(0, _idx);
        }
        if (this.dateRange.endDate) {
          const _eDt = moment(this.dateRange.endDate).format("YYYY-MM-DD");
          const _idx = _dtRange.indexOf(_eDt);
          _dtRange.splice(_idx + 1, _dtRange.length - _idx);
        }

        const _finalTotal = _dtRange.reduce(
          (acc, dt) => (acc += _totals[dt].cost + _totals[dt].no_pay_cost),
          0
        );

        return _finalTotal;
      }

      return 0;
    },
  },
  watch: {
    fromMobile: function(nv, ov) {
      if (nv !== ov) {
        this.setOrderFields();
        this.refreshOrders();
      }
    },
    orderItems: function(nv) {
      this.updateCellHeight(nv);
      this.$nextTick(() => {
        this.bindClip();
      });
    },
    showOrderForm: function(nv) {
      this.updateCellHeight(nv);
    },
    regular_orders: function() {
      if (this.orderLoading)
        setTimeout(() => {
          $(".b-table-sticky-header").scrollTop(
            this.orderReverse ? $(".b-table").height() : 0
          );
        }, 100);

      this.orderLoading = false;
      this.orderReverse = this.toOrderReverse;
    },
    mobile_orders: function() {
      if (this.orderLoading)
        setTimeout(() => {
          $(".b-table-sticky-header").scrollTop(
            this.orderReverse ? $(".b-table").height() : 0
          );
        }, 100);

      this.orderLoading = false;
      this.orderReverse = this.toOrderReverse;
    },
    drivers: function(nv) {
      this.updateCellHeight(nv);
    },
    paymentMethod: function(nv) {
      this.refreshOrders({
        ...this.dateRange,
        payment_method: nv !== "All" ? nv : null,
      });
    },
    mobileOnly: function(nv) {
      this.refreshOrders();
    },
    async dateRange(nv) {
      this.toOrderReverse = this.orderReverse = false;
      await this.refreshOrders({
        ...nv,
        payment_method:
          this.paymentMethod !== "All" ? this.paymentMethod : null,
      });

      if (nv.startDate && nv.endDate) {
        let myDateRange = JSON.stringify({
          startDate: nv.startDate.getTime(),
          endDate: nv.endDate.getTime(),
        });
        if (localStorage.sortingDateRange != myDateRange)
          localStorage.sortingDateRange = myDateRange;
      }
    },
  },
  methods: {
    updateRank: debounce(
      function(item, index) {
        if (item.rank != "__" && index != this.perPage - 1) {
          this.setOrder(item);
        }
      },
      300,
      { leading: false }
    ),
    print: () => {
      var css = "@page { size: A4 landscape; }",
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");

      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      head.appendChild(style);

      window.print();
    },
    dateRangeOptionChanged(option) {
      if (option == "Date Range" && localStorage.sortingDateRange) {
        let myDateRange = JSON.parse(localStorage.sortingDateRange);
        this.dateRange = {
          startDate: new Date(myDateRange.startDate),
          endDate: new Date(myDateRange.endDate),
        };
      }
    },
    bindClip() {
      let btns = $(".clip").not(".inited-clip");
      let myvue = this;

      if (btns.length) {
        btns
          .each(function() {
            var clips = new ClipboardJS(this);

            clips.on("success", function(e) {
              myvue.$notify({
                text: "Order data copied to the clipboard!",
                type: "success",
              });
            });

            clips.on("error", function(e) {
              console.log(e);
            });
          })
          .addClass("inited-clip");
      }
    },
    updateCellHeight(nv) {
      this.$nextTick(() => {
        if (this.$refs.orderTable) {
          let orderTable = $(this.$refs.orderTable.$el);
          orderTable
            .find(".first-col .form-inline, .action-col .btn-group")
            .css("height", "");

          orderTable.find(".action-col").each((index, el) => {
            if (index > 0) {
              let colHeight = $(el).height();
              $(el)
                .find(".btn-group")
                .height(colHeight);
            }
          });
          orderTable.find(".first-col").each((index, el) => {
            if (index > 0) {
              let colHeight = $(el).height();
              $(el)
                .find(".form-inline")
                .height(colHeight);
            }
          });
        }
      });
    },
    ...mapActions({
      saveOrder: "orders/set",
      deleteOrder: "orders/delete",
      getMonthlyTotal: "orders/getMonthlyOrdersTotal",
      getDailyTotal: "orders/getDailyOrdersTotal",
      getOrders: "orders/get",
      setDriver: "drivers/set",
    }),
    toggleColorPicker(row, key = "color") {
      this.showColorSlider = true;
      this.selectedRowForColorSlider = row;
      this.selectedKeyForColorSlider = key;
    },
    hideColorPickers() {
      this.myColor = "transparent";
      this.showColorSlider = false;
      this.selectedRowForColorSlider = null;
      this.selectedKeyForColorSlider = null;
    },
    async colorSelected() {
      if (this.selectedRowForColorSlider && this.selectedKeyForColorSlider) {
        let item = this.selectedRowForColorSlider.item;
        set(item, this.selectedKeyForColorSlider, this.myColor);
        // item[this.selectedKeyForColorSlider] = this.myColor;

        this.hideColorPickers();

        await this.setOrder(item, false);
      }
    },
    removeColor(item, key = "color") {
      // item[key] = null;
      set(item, key, "transparent");
      this.setOrder(item, false);
    },
    otherPhonesContent(order) {
      if (order.customer && isArray(order.customer.otherPhones)) {
        let phoneContents = [];
        order.customer.otherPhones.forEach((op, op_index) => {
          let pcontent = op;

          if (
            isArray(order.customer.otherPhoneDescriptions) &&
            order.customer.otherPhoneDescriptions[op_index]
          ) {
            pcontent += ` <small>(${order.customer.otherPhoneDescriptions[op_index]})</small>`;
          }

          phoneContents.push(pcontent);
        });

        return `<div style='font-size:.8em; line-height: 1.4;'>${phoneContents.join(
          "<br>"
        )}</div>`;
      }

      return "";
    },
    toggleNotice(item, to) {
      if (!isUndefined(to) && isBoolean(to)) item.notice = to;
      else item.notice = !item.notice;

      if (item.notice) {
        item.color = item.instruction_color = item.remark_color = null;
      }

      this.setOrder(item, false);
    },
    rowClass(item, type) {
      if (item && item.notice) {
        return "row-notice";
      }
    },
    clipButtonClick(item) {
      this.toggleNotice(item, true);
      // this.setOrder(item, false);
    },
    showOrder(order) {
      this.showOrderForm = true;
      this.selectedOrder = order;
    },
    hideOrder() {
      this.showOrderForm = false;
      this.selectedOrder = null;
      this.showRecentOrders = false;
    },
    useRecentOrder(order) {
      this.selectedOrder = Object.assign({}, order, {
        orderId: null,
        notice: null,
        color: null,
        address_color: null,
        instruction_color: null,
        remark_color: null,
        no_pay_cost: null,
        tips: null,
        driverId: null,
        dateTime: null,
        date: null,
        time: null,
        fromMobile: null,
      });
      this.showRecentOrders = false;
    },
    hideRecentOrders() {
      this.showRecentOrders = false;
    },
    rowCopyClipboard(item) {
      return this.stringHelpers.clipboardMsgFormatter(item);
    },
    loadPreviousOrders() {
      let endDate = new Date();

      if (this.orderItems?.length > 0) {
        endDate = new Date(this.orderItems[0].dateTime.seconds * 1000 - 1);
      }

      this.toOrderReverse = true;
      this.orderLoading = true;

      this.getOrders({
        realtime: true,
        count: this.perPage,
        order: "desc",
        fromMobile: this.fromMobile,
        mobileOnly: this.mobileOnly,
        stateKey: this.fromMobile ? "mobileOrders" : "orders",
        filters: {
          payment_method:
            this.paymentMethod !== "All" ? this.paymentMethod : null,
          endDate,
        },
      });
    },
    loadNextOrders() {
      if (this.orderItems?.length > 0) {
        let startDate = new moment(
          this.orderItems[this.orderItems.length - 1].dateTime.seconds * 1000 +
            1
        );
        startDate = startDate.toDate();

        console.log(startDate);

        this.orderLoading = true;
        this.toOrderReverse = false;

        this.getOrders({
          realtime: true,
          count: this.perPage,
          order: "asc",
          fromMobile: this.fromMobile,
          mobileOnly: this.mobileOnly,
          stateKey: this.fromMobile ? "mobileOrders" : "orders",
          filters: {
            payment_method:
              this.paymentMethod !== "All" ? this.paymentMethod : null,
            startDate,
          },
        });
      }
    },
    async refreshOrders(
      filters = {
        startDate: this.dateRange.startDate,
      }
    ) {
      this.orderLoading = true;

      await this.getOrders({
        realtime: true,
        count: this.perPage,
        filters,
        fromMobile: this.fromMobile,
        mobileOnly: this.mobileOnly,
        stateKey: this.fromMobile ? "mobileOrders" : "orders",
      });

      this.hideColorPickers();
    },
    async setOrder(order, resetNotice = true) {
      if (resetNotice) order.notice = false;
      await this.saveOrder({
        orderId: order.orderId,
        data: order,
        user: this.currentUser,
      });

      this.$notify({
        text: "Order saved successfully",
        type: "success",
      });
    },
    async getCustomerOrders(order) {
      await this.$store.dispatch("orders/get", {
        count: 100,
        order: "desc",
        filters: {
          customerId: order.customerId,
        },
        stateKey: "recentOrders",
      });

      this.showRecentOrders = true;
    },
    async unsetOrder(order) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this order? (It cannot be undone)"
        )
        .then(async (value) => {
          if (value) {
            await this.deleteOrder(order.orderId);

            this.$notify({
              text: "Order removed successfully",
              type: "success",
            });
          }
        });
    },
    async createDriver(option, order) {
      let selectedDriverName = option.text,
        existingDriver = find(
          this.drivers,
          (d) =>
            d.text.toLowerCase().trim() ==
            selectedDriverName.toLowerCase().trim()
        );

      if (!existingDriver) {
        const h = this.$createElement;
        const messageVNode = h("p", {
          domProps: {
            innerHTML: `Create a new driver with the name ${option.text}? <br><small>(Please make sure this driver name is not in the database)</small>`,
          },
        });

        this.$bvModal
          .msgBoxConfirm([messageVNode], {
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            cancelTitle: "Cancel",
            noCloseOnEsc: true,
            noCloseOnBackdrop: true,
            headerClass: "border-bottom-0 pb-0",
            footerClass: "border-top-0 pt-0",
            centered: true,
          })
          .then(async (value) => {
            if (value) {
              await this.setDriver({
                driverId: null,
                data: { name: option.text },
              }).then((docRef) => {
                order.driverId = docRef.id;
                this.setOrder(order);
              });
            }
          });
      }
    },
    selectDriver(option, order) {
      let selectedDriverName = option.text,
        existingDriver = find(
          this.drivers,
          (d) =>
            d.text.toLowerCase().trim() ==
            selectedDriverName.toLowerCase().trim()
        );

      if (existingDriver) {
        order.driverId = existingDriver.value;
        this.setOrder(order);
      }
    },
    clearDriver(order) {
      order.driverId = "";
      this.setOrder(order);
    },
    modalOrderSubmitting(order) {
      order.notice = false;
    },
    setOrderFields() {
      this.orderFields = [
        {
          key: "status",
          label: "訂單狀態",
        },
        {
          key: "dateTime",
          label: "日期/時間",
        },
        {
          key: "locations",
          label: "地址",
          tdClass(value, key, item) {
            return "loc-col";
          },
        },
        {
          key: "information",
          label: "訂單資訊",
        },
        {
          key: "remark",
          label: "備註 & 顧客要求",
          tdClass: "remark-col",
          tdAttr(value, key, item) {
            if (item.remark_color) {
              let color = item.remark_color.hex || item.remark_color;
              if (color)
                return {
                  style: `background-color: ${item.remark_color.hex ||
                    item.remark_color}; overflow: hidden`,
                };
            } else {
              return {};
            }
          },
        },
        {
          key: "customer_passenger",
          label: "訂車人/乘客",
        },
        {
          key: "notice",
          label: "",
          class: "action-col",
        },
        {
          key: "edit",
          label: "",
          class: "action-col",
        },
        {
          key: "copy",
          label: "",
          class: "action-col",
        },
        {
          key: "delete",
          label: "",
          class: "action-col",
        },
      ];

      if (!this.fromMobile) {
        this.orderFields.unshift({
          key: "driver",
          label: "司機",
          stickyColumn: true,
          class: "first-col",
          _showDetails: true,
        });

        this.orderFields.splice(5, 0, {
          key: "instruction",
          label: "司機指示",
          tdAttr(value, key, item) {
            if (item.instruction_color) {
              let color = item.instruction_color.hex || item.instruction_color;
              if (color)
                return {
                  style: `background-color: ${item.instruction_color.hex ||
                    item.instruction_color}`,
                };
            } else return {};
          },
        });

        this.orderFields.splice(9, 0, {
          key: "notice",
          label: "",
          class: "action-col",
        });

        this.orderFields.splice(11, 0, {
          key: "copy",
          label: "",
          class: "action-col",
        });
      } else {
        remove(this.orderFields, function(f) {
          return f.key == "copy" || f.key == "notice";
        });
      }
    },
  },
  created() {
    // By default, fetch all orders from today current time.
    this.setOrderFields();
    this.refreshOrders();
    this.getMonthlyTotal();
    this.getDailyTotal();
  },
  beforeDestroy() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
#main-col > .box {
  height: 100%;
}

#table-wrapper {
  height: calc(100vh - 100px);
  position: relative;
  margin: 0 -0.75rem;
}

.b-table-sticky-header {
  max-height: none;
  height: 100%;
  margin: 0;
  width: auto;
}

.vs__selected {
  overflow: hidden !important;
}

.custom-select {
  width: auto;
}

// Columns
.first-col {
  > div {
    position: relative;
    padding: 0 1.25rem 0 0.25rem;

    > div {
      margin-top: 0.5rem;
      align-items: center;
    }

    .color-picker-trigger {
      position: absolute;
      width: 0.75rem;
      left: 0;
      top: 0;
      height: 100%;

      &:after {
        content: none;
      }
    }
  }

  .clear-driver {
    font-size: 0.85em;
  }
}

// Cell Elements
input.form-control.input-order {
  width: 2.5em;
  text-align: center;
}

.createdBy {
  position: absolute;
  bottom: 0.25rem;
  left: 0.35rem;
  font-size: 0.8em;
  line-height: 1.35;
}

.driver_instruction_cell {
  width: 140px;
  padding-right: 10px;
}

.cus-pas-label {
  min-width: 40px;
  display: block;
  font-weight: bold;
}

.drivers-select {
  max-width: 70px;
}

.action-col {
  .btn-secondary {
    &:not(:last-child) {
      border-right-color: #fff !important;
    }
  }

  .btn-secondary:not(:disabled):not(.disabled).active {
    background: rgb(219, 223, 0);
    border-top-color: rgb(219, 223, 0);
    border-bottom-color: rgb(219, 223, 0);
    border-right-color: rgb(219, 223, 0);
  }
}

#btn-toggleRecentOrder {
  position: relative;
  z-index: 1;
}
</style>
