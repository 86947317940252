<template>
  <div class="box sm-box mb-4">
    <h3 class="h5 mb-2">
      {{ order.dateTime.toDate() | formatDate("MMM DD, YYYY - HH:mm") }}
      <b-badge
        :variant="statusHelpers.statusVariant(order.status)"
        class="status"
        >{{ order.status }}</b-badge
      >
    </h3>

    <OrderMeta :order="order">
      <template v-slot:afterMeta>
        <a
          @click.prevent
          href="#"
          v-b-popover.hover.leftbottom="instruction_and_remark"
          class="show-info no-underline"
        >
          <b-icon icon="info-circle-fill"></b-icon>
        </a>
      </template>
    </OrderMeta>
    <hr class="mx-0" />
    <p class="passengers">
      <span class="d-inline-block pr-3">
        訂車人 - {{ order.customer.name }}<br />
        {{ order.customer.phone }}
      </span>
      <span class="d-inline-block" v-if="!$_.isEmpty(order.passenger)">
        坐車人 - {{ order.passenger.name }}<br />
        {{ order.passenger.phone }}
      </span>
    </p>

    <div
      class="loc-list"
      v-for="(loc, loc_index) in order.locations"
      :key="`order_loc_${loc_index}`"
    >
      {{ loc }}
    </div>

    <b-button-group class="button-row mt-4">
      <b-button
        class="btn-reuse"
        @click="$emit('useOrder', order)"
        v-if="!(this.showReuseOrder === false)"
        >重用訂單資料</b-button
      >

      <OrderModal buttonVariant="secondary" :order="order">查看訂單</OrderModal>
    </b-button-group>
  </div>
</template>

<script>
import OrderMeta from "./OrderMeta.vue";
import OrderModal from "./OrderModal.vue";

export default {
  components: { OrderMeta, OrderModal },
  props: ["order", "showReuseOrder"],
  computed: {
    instruction_and_remark() {
      return {
        html: true,
        title: "",
        content: () => {
          return `<div><strong class="d-block mb-2">司機指示</strong>${this
            .order.instruction ??
            ""}</div><hr class="my-2"><div><strong class="d-block mb-2">備註</strong>${this
            .order.remark ?? ""}</div>`;
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variable";

.box {
  padding-bottom: 0;
  position: relative;
}

.status {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  border-radius: 10px;
  padding: 5px;
  font-size: 0.7rem;
  text-transform: uppercase;
}

.passengers {
  font-size: 0.9rem;

  > span {
    vertical-align: top;
  }
}

.button-row {
  width: auto;
  margin: 0 -1rem 0 -0.75rem;
  display: flex;

  > .btn {
    &:first-child {
      border-top-left-radius: 0;
    }
    &:last-child {
      border-top-right-radius: 0;
    }
  }
}

.btn-reuse {
  background-color: #ccc;
  border-color: #ccc;
}
.btn-view {
  background-color: #999;
  border-color: #999;
}

.show-info {
  color: #000;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 1.25rem;
  svg {
    display: block;
  }
}

.loc-list {
  font-size: 0.9rem;
  padding-bottom: 3px;
  & + .loc-list {
    border-top: 1px solid $border-color;
    margin-top: 3px;
    padding-top: 5px;
  }
}
</style>
