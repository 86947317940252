<template>
  <b-button
    :size="buttonSize || ''"
    :variant="buttonVariant ? buttonVariant : 'primary'"
    @click="modalVisibility = true"
  >
    <slot></slot>

    <b-modal
      :visible="modalVisibility"
      size="xl"
      :hide-header="true"
      :hide-footer="true"
      centered
      @hide="modalHide"
    >
      <OrderForm
        class="px-2 py-0"
        :showOrderForm="true"
        @orderSubmitting="orderSubmitting"
        @orderSubmitted="orderSubmitted"
        @orderSubmitError="orderSubmitError"
        :customer="orderForEdit.customer"
        :passInOrder="orderForEdit"
        :fromMobile="fromMobile"
      >
      </OrderForm>
    </b-modal>
  </b-button>
</template>

<script>
import { cloneDeep } from "lodash";

import OrderForm from "./OrderForm.vue";

export default {
  components: { OrderForm },
  props: ["order", "buttonVariant", "buttonSize", "fromMobile"],
  data() {
    return {
      modalVisibility: false,
      orderForEdit: cloneDeep(this.order),
    };
  },
  watch: {
    order() {
      this.orderForEdit = this.order;
    },
  },
  methods: {
    modalHide() {
      this.modalVisibility = false;
    },
    orderSubmitting(order) {
      this.$emit("orderSubmitting", order);
    },
    orderSubmitted(orderId) {
      this.modalHide();
      this.$emit("orderSubmitted", orderId);
    },
    orderSubmitError() {
      this.modalHide();
    },
  },
};
</script>
