import {
  setDoc,
  doc,
  getFirestore,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";

const COLLECT = "settings";

export default {
  getSettings() {
    const db = getFirestore();
    return doc(db, COLLECT, "settings");
  },
  setSettings(data) {
    const db = getFirestore();
    const q = doc(db, COLLECT, "settings");
    setDoc(q, data, { merge: true });
  },
  addSetting(type, val) {
    const db = getFirestore();
    return updateDoc(doc(db, COLLECT, "settings"), {
      [type]: arrayUnion(val),
    });
  },
};
