import api from "../../api/index";
import { onSnapshot } from "firebase/firestore";

// initial state
const state = () => ({
  settings: {},
});

// getters
const getters = {};

// actions
const actions = {
  async get({ commit }) {
    onSnapshot(api.settings.getSettings(), (docSnap) => {
      commit("setSettings", docSnap.data());
    });
  },

  async set(context, data) {
    await api.settings.setSettings(data);
  },
};

// mutations
const mutations = {
  setSettings(state, val) {
    state.settings = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

// TODO: Location settings
