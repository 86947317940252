<template>
  <ais-instant-search :search-client="searchClient" :index-name="index">
    <ais-autocomplete>
      <div slot-scope="{ refine }">
        <b-form-input
          type="search"
          v-model="userSearch"
          :placeholder="placeholder"
          @input.native="refineSearch(refine, $event.currentTarget.value)"
          @change.native="$emit('change', $event.currentTarget.value)"
        ></b-form-input>
        <ais-hits>
          <ul
            class="dropdown-menu"
            :class="{
              show: userSearch && items.length > 0,
              static: isStatic,
            }"
            slot-scope="{ items }"
          >
            <li v-for="item in items" :key="item.objectID">
              <div class="search-item" @click="onSelect(item.objectID)">
                <div class="search-item-title">
                  <b>{{ item[title] || "No Name" }}</b>
                  <template v-if="item['_highlightResult'].phone">
                    -
                    <span v-html="item['_highlightResult'].phone.value"></span>
                  </template>
                </div>
                <div class="search-item-subtitle">
                  <span
                    class="d-inline-block mr-2"
                    v-for="(ad, index) in item['_highlightResult'][subtitle]"
                    :key="`subtitle-${ad.value}-${index}`"
                    v-html="ad.value"
                  >
                  </span>
                </div>
                <div
                  class="search-item-subtitle search-item-subtitle-addresses"
                  v-if="$_.isArray(item['_highlightResult'].addresses)"
                >
                  <span
                    class="d-inline-block mr-2"
                    v-for="(ad, index) in item['_highlightResult'].addresses"
                    :key="`subtitle-addresses-${index}`"
                    v-html="ad.value"
                  >
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </ais-hits>
      </div>
    </ais-autocomplete>
  </ais-instant-search>
</template>

<script>
import { mapState } from "vuex";
import algoliasearch from "algoliasearch/lite";
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_API_ID } from "../constants";
import { debounce } from 'lodash';

export default {
  name: "SearchInput",
  props: [
    "index",
    "value",
    "placeholder",
    "title",
    "subtitle",
    "onClick",
    "isStatic",
  ],
  computed: {
    ...mapState({
      statuses: (state) => state.settings.settings.status,
    }),
  },
  data() {
    return {
      userSearch: "",
      searchClient: algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_API_ID),
    };
  },
  methods: {
    getCustomer(customerId) {
      this.$store.dispatch("customers/getSingle", customerId);
    },
    onSelect(id) {
      this.onClick != null ? this.onClick(id) : this.getCustomer(id);
      this.userSearch = null;
    },
    refineSearch: debounce(function(refine, value) {
      this.searchQuery = value
      refine(value)
    }, 600),
  },
  created() {
    if (this.index == "Customers")
      this.$store.commit("customers/resetCustomer");
  },
};
</script>

<style lang="scss" scoped>
.ais-Autocomplete {
  position: relative;
}

.dropdown-menu {
  top: auto;
  left: auto;
  max-height: 30vh;
  overflow: auto;
  width: 100%;

  &.static {
    position: static;
    margin-top: 5px;
  }

  li:hover {
    background-color: rgba(169, 169, 169, 0.25);
  }
}

.search-item {
  cursor: pointer;
  padding: 4px 16px;
}

.search-item-subtitle {
  display: block;
  font-size: 11px;
  color: grey;

  & + .search-item-subtitle {
    margin-top: 2px;
  }
}

.search-item-subtitle-addresses {
  overflow: hidden;
  white-space: nowrap;
}
</style>
