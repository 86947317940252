import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import _ from "lodash";
Object.defineProperty(Vue.prototype, "$_", { value: _ });

import moment from "moment";
Object.defineProperty(Vue.prototype, "moment", { value: moment });

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import * as VueGoogleMaps from "vue2-google-maps";
let key = moment().get('hour') > 12 ? "AIzaSyCg96SRkh8aF0195jevjzHTPcQLJhgDiYI" : "AIzaSyDdxa_5-tjPZD7OjTMiRiFn1j5msYrZQW4";
let myhour = moment().get('hour');

switch (myhour % 3) {
  case 0:
    key = "AIzaSyCg96SRkh8aF0195jevjzHTPcQLJhgDiYI";
    console.log("// on stephen.chiu@binaray01edge.com");
    break;
  case 1:
    key = "AIzaSyDdxa_5-tjPZD7OjTMiRiFn1j5msYrZQW4";
    console.log("// on schiu@binaryedge.ca");
    break;
  default:
    key = "AIzaSyCdbzuIgFqeVj_5hE1dcRxRa52XHnnDaWo";
    console.log("// on stephen.chiu@binaray01edge.com");
    break;
}

// console.log(key);

Vue.use(VueGoogleMaps, {
  load: {
    key,
    // This is required if you use the Autocomplete plugin
    libraries: "places",
    language: "en",
  },
  installComponents: true,
});

import InstantSearch from "vue-instantsearch";
Vue.use(InstantSearch);
import Notifications from 'vue-notification';
Vue.use(Notifications);

import VCalendar from 'v-calendar';
Vue.use(VCalendar);

import helpers from '@/helpers';
Vue.use(helpers);

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "./assets/scss/app.scss";

import wb from "./registerServiceWorker";
Vue.prototype.$workbox = wb;

Vue.config.productionTip = false;

Vue.filter("formatDate", function (value, format = "MM/DD/YYYY") {
  if (_.isDate(value)) {
    return moment(value).format(format);
  } else if (_.isInteger(value)) {
    return moment(new Date(value)).format(format);
  } else if (value) {
    return moment(String(value)).format(format);
  }
});



let app;

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");

    if (user) store.dispatch("user/fetchUserProfile", user);
  }
});


