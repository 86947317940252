var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":_vm.index}},[_c('ais-autocomplete',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var refine = ref.refine;
return _c('div',{},[_c('b-form-input',{attrs:{"type":"search","placeholder":_vm.placeholder},nativeOn:{"input":function($event){return _vm.refineSearch(refine, $event.currentTarget.value)},"change":function($event){return _vm.$emit('change', $event.currentTarget.value)}},model:{value:(_vm.userSearch),callback:function ($$v) {_vm.userSearch=$$v},expression:"userSearch"}}),_c('ais-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _c('ul',{staticClass:"dropdown-menu",class:{
            show: _vm.userSearch && items.length > 0,
            static: _vm.isStatic,
          }},_vm._l((items),function(item){return _c('li',{key:item.objectID},[_c('div',{staticClass:"search-item",on:{"click":function($event){return _vm.onSelect(item.objectID)}}},[_c('div',{staticClass:"search-item-title"},[_c('b',[_vm._v(_vm._s(item[_vm.title] || "No Name"))]),(item['_highlightResult'].phone)?[_vm._v(" - "),_c('span',{domProps:{"innerHTML":_vm._s(item['_highlightResult'].phone.value)}})]:_vm._e()],2),_c('div',{staticClass:"search-item-subtitle"},_vm._l((item['_highlightResult'][_vm.subtitle]),function(ad,index){return _c('span',{key:("subtitle-" + (ad.value) + "-" + index),staticClass:"d-inline-block mr-2",domProps:{"innerHTML":_vm._s(ad.value)}})}),0),(_vm.$_.isArray(item['_highlightResult'].addresses))?_c('div',{staticClass:"search-item-subtitle search-item-subtitle-addresses"},_vm._l((item['_highlightResult'].addresses),function(ad,index){return _c('span',{key:("subtitle-addresses-" + index),staticClass:"d-inline-block mr-2",domProps:{"innerHTML":_vm._s(ad.value)}})}),0):_vm._e()])])}),0)}}],null,true)})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }