const env = process.env;

const ALGOLIA_APP_ID_PROD = "C76ZMV392P";
const ALGOLIA_SEARCH_API_ID_PROD = "ed5465fa231c63020eaebbf5cece2006";

const ALGOLIA_APP_ID_DEV = "65JTB2W2JE";
const ALGOLIA_SEARCH_API_ID_DEV = "b25e265a4d1a8dc99ef0f53b5262a1bd";

export const ALGOLIA_APP_ID = env.VUE_APP_ALGOLIA_APP_ID || ALGOLIA_APP_ID_DEV;
export const ALGOLIA_SEARCH_API_ID = env.VUE_APP_ALGOLIA_SEARCH_API_ID || ALGOLIA_SEARCH_API_ID_DEV;
