import api from "../../api/index";
import { onSnapshot } from "firebase/firestore";

// initial state
const state = () => ({
  drivers: {},
});

// getters
const getters = {};

// actions
const actions = {
  async get({ commit }) {
    onSnapshot(api.drivers.getDrivers(), (querySnapshot) => {
      const _drivers = {};
      querySnapshot.forEach((doc) => {
        const _driver = doc.data();
        _driver["driverId"] = doc.id;
        _drivers[doc.id] = _driver;
      });
      commit("setDriver", _drivers);
    });
  },

  async set(context, { driverId, data }) {
    return await api.drivers.setDriver(driverId, data);
  },

  async delete(context, driverId) {
    return await api.drivers.deleteDriver(driverId);
  },
};

// mutations
const mutations = {
  setDriver(state, val) {
    state.drivers = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
