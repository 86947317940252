import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import {
  getDocs,
  getDoc,
  doc,
  updateDoc,
  getFirestore,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

const COLLECT = "customers";

export default {
  getCustomer(customerId) {
    const db = getFirestore();
    return getDoc(doc(db, COLLECT, customerId));
  },
  getCustomers(options) {
    const db = firebase.firestore();
    let q = db.collection(COLLECT);

    if (options?.filters) {
      const filters = options?.filters;
      if (filters?.phone) {
        q = q.where("phone", "==", filters?.phone);
      }
      if (filters?.name) {
        q = q.where("name", "==", filters?.name);
      }
      if (filters?.businessCode) {
        q = q.where("businessCode", "==", filters?.businessCode);
      }
      if (filters?.notCustomerId) {
        q = q.where("customerId", "!=", filters?.notCustomerId);
      }
      if (filters?.address) {
        q = q.where("addresses", "array-contains", filters?.address);
      }
    }

    if (options?.count) {
      q = q.limit(options?.count);
    }

    const _orderBy = options?.orderBy || "name";
    const _order = options?.order || "asc";
    q = q.orderBy(_orderBy, _order);

    return getDocs(q);
  },
  setCustomer(customerId, data) {
    const db = firebase.firestore();
    let q = db.collection(COLLECT);

    if (customerId == null) {
      return q.add(data);
    }

    return q.doc(customerId).set(data, { merge: true });
  },
  addPassenger(customerId, passenger) {
    const db = getFirestore();
    return updateDoc(doc(db, COLLECT, customerId), {
      passengers: arrayUnion(...passenger),
    });
  },
  async updatePassenger(customerId, oldPassenger, newPassenger) {
    await this.deletePassenger(customerId, oldPassenger);
    return this.addPassenger(customerId, newPassenger);
  },
  deletePassenger(customerId, passenger) {
    const db = getFirestore();
    return updateDoc(doc(db, COLLECT, customerId), {
      passengers: arrayRemove(passenger),
    });
  },
  deleteCustomer(customerId) {
    const db = firebase.firestore();
    let q = db.collection(COLLECT);

    return q.doc(customerId).delete();
  },
};
