<template>
  <p class="meta">
    <span v-if="!$_.isUndefined(order.cost)"
      >${{ order.cost }}<span class="meta-sep">|</span></span
    >
    <span v-if="!$_.isUndefined(order.receipt)"
      >Receipt ${{ order.receipt }}<span class="meta-sep">|</span></span
    >
    <span v-if="!$_.isUndefined(order.cost_407)"
      >407 ${{ order.cost_407 }}<span class="meta-sep">|</span></span
    >
    <span v-if="!$_.isUndefined(order.on_behalf_cost)"
      ><span class="d-inline-block">代收</span> ${{ order.on_behalf_cost
      }}<span class="meta-sep">|</span></span
    >
    <span v-if="order.payment_method">{{
      `${order.payment_method} ${
        order.businessName ? `(${order.businessName})` : ""
      }`
    }}</span>
    <span
      v-if="!$_.isUndefined(order.language) && order.language != 'cantonese'"
      ><span class="meta-sep">|</span
      >{{
        order.language == "english"
          ? "英"
          : order.language == "mandarin"
          ? "國"
          : ""
      }}</span
    >
    <br />

    <span class="meta-spacing">
      <b-icon icon="person-fill"></b-icon> {{ order.passenger_count }}
    </span>
    <span class="meta-spacing">
      <b-icon icon="briefcase-fill"></b-icon> {{ order.luggage_count }}
    </span>
    <span class="meta-spacing">
      <b-icon icon="handbag-fill"></b-icon> {{ order.handcarry_count }}
    </span>

    <template
      v-if="
        $_.isArray(order.other_luggages) &&
          !$_.every(order.other_luggages, $_.isEmpty)
      "
    >
      <span class="meta-sep">|</span>
      {{ order.other_luggages.join(", ") }}
    </template>

    <span class="meta-spacing" v-if="order.fromMobile">
      <b-icon icon="phone"></b-icon> {{ order.fromMobile && "Mobile" }}
    </span>

    <slot name="afterMeta"></slot>
  </p>
</template>

<script>
export default {
  props: ["order"],
};
</script>

<style lang="scss" scoped>
.meta {
  text-transform: uppercase;
  line-height: 1.95;
  font-size: 0.95rem;
  position: relative;

  .meta-spacing {
    + .meta-spacing {
      margin-left: 5px;
    }
  }

  .meta-sep {
    margin-left: 3px;
    margin-right: 3px;
  }

  > span {
    display: inline-block;
  }
}
</style>
