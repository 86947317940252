<template>
  <div class="box full-box" id="input-box">
    <b-row class="mx-n4">
      <b-col class="px-4" id="first-col">
        <slot name="beforeForm"></slot>

        <div v-if="showOrderForm" class="mt-4">
          <div
            class="box outline-box mb-4"
            v-if="order.orderId != null || customer == null"
          >
            <b-row class="gx-1" align-v="center">
              <b-col>
                <b-form-input
                  v-if="customer == null"
                  v-model="newCustomer.name"
                  placeholder="訂車人姓名"
                ></b-form-input>
                <b-form-input v-else v-model="customer.name" readonly>
                </b-form-input>
              </b-col>
              <b-col>
                <the-mask
                  v-model.trim="newCustomer.phone"
                  :class="{
                    'form-control': true,
                    'is-invalid':
                      !isPhoneNumber(newCustomer.phone) && formDirty,
                  }"
                  placeholder="電話"
                  :masked="true"
                  :mask="['(###) ###-####', '(##) #### #######', '(###) #### ####']"
                  v-if="customer == null"
                />
                <b-form-input v-else v-model="customer.phone" readonly>
                </b-form-input>
              </b-col>
              <b-col cols="auto" v-if="order.customerId">
                <router-link
                  target="_blank"
                  :to="{
                    path: 'customers',
                    query: { customerId: order.customerId },
                  }"
                  class="no-underline"
                >
                  <b-icon-pencil></b-icon-pencil>
                </router-link>
              </b-col>
            </b-row>

            <div
              v-if="
                customer != null &&
                  $_.isArray(customer.otherPhones) &&
                  customer.otherPhones.length > 0
              "
              class="mb-0 mt-2"
            >
              <small>
                <strong>其他電話:</strong>
                <span
                  class="mr-2"
                  v-for="(op, op_index) in customer.otherPhones"
                  :key="`op-${op_index}`"
                >
                  {{ op }}
                  <small
                    v-if="
                      customer.otherPhoneDescriptions &&
                        customer.otherPhoneDescriptions[op_index]
                    "
                  >
                    ({{ customer.otherPhoneDescriptions[op_index] }})
                  </small>
                </span>
              </small>
            </div>
          </div>

          <b-row align-v="center" class="mb-4">
            <b-col cols="auto">
              <b-form-checkbox
                :id="'input-customer-is-passenger-' + order.orderId"
                v-model="order.customer_isnot_passenger"
                name="customer-is-passenger"
              >
                訂車人為不同乘客
              </b-form-checkbox>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <div class="form-inline">
                <label class="mr-2" for="input-language">語言</label>
                <b-form-select
                  size="sm"
                  v-model="$v.order.language.$model"
                  id="input-language"
                  :state="computeValidity($v.order.language.$invalid)"
                >
                  <b-form-select-option value="cantonese"
                    >粵</b-form-select-option
                  >
                  <b-form-select-option value="mandarin"
                    >國</b-form-select-option
                  >
                  <b-form-select-option value="english"
                    >英</b-form-select-option
                  >
                </b-form-select>

                <span class="position-relative">
                  <a
                    href="#"
                    class="no-underline ml-2"
                    @click.prevent="
                      showOrderColorPicker = !showOrderColorPicker
                    "
                  >
                    <b-icon
                      icon="droplet-fill"
                      v-if="order.color == 'transparent'"
                    ></b-icon>
                    <span
                      class="droplet"
                      :style="{
                        'background-color': order.color.hex,
                      }"
                      v-else
                    ></span>
                  </a>

                  <a
                    href="#"
                    class="no-underline ml-1 clear-color"
                    @click.prevent="order.color = 'transparent'"
                    v-if="order.color != 'transparent'"
                  >
                    <b-icon-x></b-icon-x>
                  </a>

                  <twitter-picker
                    v-if="showOrderColorPicker"
                    :defaultColors="defaultColors"
                    triangle="hide"
                    v-model="order.color"
                    @input="orderColorChanged"
                    class="vc-t-picker"
                  >
                  </twitter-picker>
                </span>
              </div>
            </b-col>
          </b-row>

          <template v-if="order.customer_isnot_passenger">
            <div class="box outline-box sm-box mb-2 pr-2 mb-4">
              <template v-if="$_.isArray(order.passenger)">
                <b-row
                  class="gx-1 mb-2"
                  align-v="end"
                  v-for="(p, p_index) in order.passenger"
                  :key="`order_passenger_${p_index}`"
                >
                  <b-col>
                    <label v-if="p_index == 0">乘客</label>
                    <b-form-input v-model.trim="p.name"></b-form-input>
                  </b-col>
                  <b-col>
                    <label v-if="p_index == 0">乘客電話</label>
                    <the-mask
                      v-model.trim="p.phone"
                      :class="{
                        'form-control': true,
                        'is-invalid': !isPhoneNumber(p.phone) && formDirty,
                      }"
                      :masked="true"
                      :mask="['### #### ####', '###-###-####']"
                    />
                  </b-col>
                  <b-col>
                    <label v-if="p_index == 0">備註</label>
                    <b-form-input v-model="p.remark"></b-form-input>
                  </b-col>
                  <b-col cols="auto" class="px-1">
                    <a
                      href="#"
                      @click.prevent="order.passenger.splice(p_index, 1)"
                      class="no-underline passenger-remove"
                    >
                      <b-icon-x></b-icon-x>
                    </a>
                  </b-col>
                </b-row>
              </template>

              <div class="text-center">
                <a
                  href="#"
                  v-if="customer && customer.passengers"
                  @click.prevent="$bvModal.show('customer-passengers-modal')"
                >
                  乘客 +
                </a>
                <a href="#" v-else @click.prevent="order.passenger.push({})">
                  乘客 +
                </a>
              </div>
            </div>

            <b-modal
              id="customer-passengers-modal"
              :centered="true"
              :hide-footer="true"
              :hide-header="true"
            >
              <h6>Choose passenger</h6>

              <b-list-group v-if="customer && customer.passengers">
                <b-list-group-item
                  @click.prevent="newOrderPassenger(p)"
                  :key="`customer_passenger_${p_index}`"
                  v-for="(p, p_index) in customer.passengers"
                  href="#"
                >
                  <strong>{{ p.name }}</strong>
                  <span class="float-right">
                    {{ p.phone }}

                    <b-icon-caret-right></b-icon-caret-right>
                  </span>
                </b-list-group-item>

                <b-list-group-item
                  href="#"
                  class="text-right"
                  @click.prevent="newOrderPassenger"
                  style="color: rgb(26, 48, 206);"
                >
                  新乘客記錄 <b-icon-caret-right></b-icon-caret-right>
                </b-list-group-item>
              </b-list-group>
            </b-modal>
          </template>

          <b-row class="text-center justify-content-around">
            <b-col cols="auto" class="mb-4">
              <label for="input-passenger-count">乘客</label>
              <b-form-input
                class="number-input"
                id="input-passenger-count"
                size="sm"
                v-model="order.passenger_count"
                type="number"
                min="0"
                placeholder="0"
                @focus="selectText($event)"
              ></b-form-input>
            </b-col>
            <b-col cols="auto" class="mb-4">
              <label for="input-luggage-count">行李</label>
              <b-form-input
                class="number-input"
                id="input-luggage-count"
                size="sm"
                v-model="order.luggage_count"
                type="number"
                min="0"
                placeholder="0"
                @focus="selectText($event)"
              ></b-form-input>
            </b-col>
            <b-col cols="auto" class="mb-4">
              <label for="input-handcarry-count">Handcarry</label>
              <b-form-input
                class="number-input"
                id="input-handcarry-count"
                size="sm"
                v-model="order.handcarry_count"
                type="number"
                min="0"
                placeholder="0"
                @focus="selectText($event)"
              ></b-form-input>
            </b-col>
          </b-row>

          <div class="mb-5">
            <p class="mb-2">
              <a
                class="float-right"
                href="#"
                @click.prevent="arrayAppend(order.other_luggages)"
                >Add +</a
              >
              <label class="mb-0" for="input-other-luggages">其他行李</label>
            </p>

            <div
              :key="`other_luggages-${lg_type}-${lg_index}`"
              v-for="(lg_type, lg_index) in orderOtherLuggages"
            >
              <b-input-group size="sm" class="mb-2">
                <b-form-input
                  class="pl-2"
                  @change="otherLuggagesChanged($event, lg_index)"
                  :value="order.other_luggages[lg_index]"
                ></b-form-input>

                <template #append>
                  <b-dropdown variant="light" size="sm" right>
                    <b-dropdown-item
                      @click="$set(order.other_luggages, lg_index, lt)"
                      :key="`luggage_types-${lt}-${idx}`"
                      v-for="(lt, idx) in settings.other_luggages.map((l) =>
                        l['zh'].toString()
                      )"
                    >
                      {{ lt }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-input-group-text>
                    <a
                      href="#"
                      @click.prevent="
                        arrayDelete(order.other_luggages, lg_index)
                      "
                      class="no-underline"
                    >
                      <b-icon icon="x"></b-icon>
                    </a>
                  </b-input-group-text>
                </template>
              </b-input-group>
            </div>
          </div>

          <b-row align-h="around" class="mb-4">
            <b-col cols="auto">
              <span class="currency-dollar">$ </span>
              <div class="d-inline-block text-center">
                <label for="input-cost">Cost</label>
                <b-form-input
                  size="sm"
                  number
                  type="number"
                  v-model="order.cost"
                  id="input-cost"
                  class="currency-input"
                  @focus="selectText($event)"
                ></b-form-input>
              </div>
            </b-col>
            <b-col cols="auto">
              <span class="currency-dollar">$ </span>
              <div class="d-inline-block text-center">
                <label for="input-receipt">R</label>
                <b-form-input
                  size="sm"
                  number
                  type="number"
                  v-model="order.receipt"
                  id="input-receipt"
                  class="currency-input"
                  @focus="selectText($event)"
                ></b-form-input>
              </div>
            </b-col>
            <b-col cols="auto">
              <span class="currency-dollar">$ </span>
              <div class="d-inline-block text-center">
                <label for="input-407">407</label>
                <b-form-input
                  size="sm"
                  number
                  type="number"
                  v-model="order.cost_407"
                  id="input-407"
                  class="currency-input"
                  @focus="selectText($event)"
                ></b-form-input>
              </div>
            </b-col>
          </b-row>

          <b-row align-v="end" class="mb-5">
            <b-col cols="auto">
              <label for="input-payment-method">Payment Method</label>
              <div>
                <Select2
                  v-model="order.payment_method"
                  :options="settings.payment_methods"
                  :settings="{
                    theme: 'bootstrap4',
                    tags: true,
                  }"
                />
              </div>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <div class="form-inline">
                <label class="mr-2" for="input-onbehalf">代收</label>
                <span class="currency-dollar">$</span>
                <b-form-input
                  size="sm"
                  number
                  id="input-onbehalf"
                  class="currency-input"
                  v-model="order.on_behalf_cost"
                  type="number"
                  @focus="selectText($event)"
                ></b-form-input>
              </div>
            </b-col>
          </b-row>

          <b-alert variant="danger" :show="!dateValidity && formDirty"
            >Make sure all the date and time is filled in and is valid
            format.</b-alert
          >
          <b-row class="mb-2 gx-2">
            <b-col cols="6">
              <label for="input-date">Date</label>
              <b-form-datepicker
                id="input-date"
                size="sm"
                v-model="order.date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                }"
              ></b-form-datepicker>
            </b-col>
            <b-col cols="6">
              <label for="input-time">Time</label>
              <masked-input
                v-model="order.time"
                :mask="{
                  pattern: 'h1:m1',
                  formatCharacters: {
                    h: {
                      validate: (char) => /[0-2]/.test(char),
                    },
                    m: {
                      validate: (char) => /[0-5]/.test(char),
                    },
                  },
                }"
                :class="{
                  'form-control-sm': true,
                  'form-control': true,
                }"
              ></masked-input>
            </b-col>
          </b-row>
          <template v-if="!order.orderId">
            <b-row
              v-for="(d, d_index) in moreDates"
              :key="`other-dates-${d_index}`"
              class="my-2 gx-2"
            >
              <b-col cols="6">
                <b-form-input
                  readonly
                  size="sm"
                  :value="d.date | formatDate('MMM DD, YYYY')"
                ></b-form-input>
              </b-col>
              <b-col cols="6">
                <b-row class="gx-1" align-v="center">
                  <b-col>
                    <masked-input
                      v-model="d.time"
                      :mask="{
                        pattern: 'h1:m1',
                        formatCharacters: {
                          h: {
                            validate: (char) => /[0-2]/.test(char),
                          },
                          m: {
                            validate: (char) => /[0-5]/.test(char),
                          },
                        },
                      }"
                      :class="{
                        'form-control-sm': true,
                        'form-control': true,
                      }"
                    ></masked-input>
                  </b-col>
                  <b-col cols="auto">
                    <a
                      href="#"
                      @click.prevent="moreDates.splice(d_index, 1)"
                      class="no-underline"
                    >
                      <b-icon-x></b-icon-x>
                    </a>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <v-date-picker
              :value="new Date()"
              class="d-block text-right"
              :model-config="{
                type: 'string',
                mask: 'MM/DD/YYYY',
              }"
              :popover="{
                keepVisibleOnInput: true,
                placement: 'top-end',
              }"
              @dayclick="onMultiDayClick"
            >
              <template v-slot="{ togglePopover }">
                <div class="mb-2">
                  <a @click.prevent="togglePopover" href="#"
                    >新增訂單其他時間</a
                  >
                </div>
              </template>
            </v-date-picker>
          </template>
          <label for="input-driver">Driver</label>
          <v-select
            label="text"
            class="input-driver"
            :appendToBody="true"
            :clearable="false"
            :taggable="true"
            :options="drivers"
            :reduce="(d) => d.value"
            :calculate-position="withPopper"
            :value="
              $_.find(drivers, (d) => d.value == order.driverId)
                ? order.driverId
                : ''
            "
            @option:created="createDriver($event)"
            @option:selected="selectDriver($event)"
          >
            <template #open-indicator>
              <b-icon icon="chevron-down"></b-icon>
            </template>
          </v-select>
          <a
            href="#"
            v-if="$_.find(drivers, (d) => d.value == order.driverId)"
            @click.prevent="clearDriver()"
            class="clear-driver"
            >clear</a
          >
        </div>
      </b-col>
      <b-col class="px-4" v-if="showOrderForm">
        <div class="d-flex flex-column h-100">
          <div class="mt-2 mb-3 text-right w-100">
            <b-button
              size="sm"
              variant="link"
              @click="resetOrder"
              v-if="!order.orderId"
            >
              <b-icon
                style="font-size: .9em;"
                icon="arrow-counterclockwise"
              ></b-icon>
              Start over
            </b-button>

            <b-button size="sm" variant="link" v-if="order.orderId"> </b-button>
          </div>

          <div class="w-100 mb-4">
            <draggable
              v-model="order.locations"
              group="location"
              handle=".input-group-prepend"
            >
              <b-col
                :key="`loc-col-${loc_index}`"
                v-for="(loc, loc_index) in order.locations"
              >
                <b-row
                  :key="`loc-row-${loc_index}`"
                  class="d-flex align-items-center mb-2 position-relative"
                >
                  <b-col sm="8" class="p-0">
                    <b-input-group
                      size="sm"
                      :style="{
                        'box-shadow':
                          order.location_colors &&
                          order.location_colors[loc_index] != 'transparent'
                            ? `0 0 0 2px ${order.location_colors[loc_index].hex}`
                            : null,
                        'border-radius': '4px',
                      }"
                    >
                      <b-input-group-prepend>
                        <b-input-group-text>
                          <b-icon icon="geo-alt-fill"></b-icon>
                        </b-input-group-text>
                      </b-input-group-prepend>

                      <!-- :value="order.locations[loc_index]" -->
                      <!-- <gmap-autocomplete
                        placeholder="Enter your address"
                        class="form-control"
                        @place_changed="touchOrderLocations($event, loc_index)"
                        @change="touchOrderLocations(null, loc_index)"
                        @hook:mounted="autoGMapMounted"
                      ></gmap-autocomplete> -->
                      <b-input v-model="order.locations[loc_index]"></b-input>

                      <b-input-group-append>
                        <b-input-group-text>
                          <a href="#" @click.prevent class="no-underline mr-2">
                            <b-icon
                              v-if="
                                !order.location_colors ||
                                  order.location_colors[loc_index] ==
                                    'transparent'
                              "
                              @click="
                                $set(
                                  showLocationsColorPicker,
                                  loc_index,
                                  !showLocationsColorPicker[loc_index]
                                )
                              "
                              icon="droplet-fill"
                            />
                            <b-icon
                              v-else
                              icon="x"
                              @click="removeLocationColor(loc_index)"
                            />
                          </a>
                          <a
                            class="no-underline"
                            style="line-height: 20px;"
                            @click.prevent="
                              $bvModal.show(
                                `modal-search-locations-${loc_index}`
                              )
                            "
                          >
                            <b-icon icon="globe"></b-icon>
                          </a>
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>

                  <b-col sm="3">
                    <b-form-input
                      size="sm"
                      placeholder="@"
                      v-model="order.location_names[loc_index]"
                      @change="locationNameChanged($event, loc_index)"
                    ></b-form-input>
                  </b-col>

                  <a
                    href="#"
                    @click.prevent="arrayDelete(order.locations, loc_index)"
                    class="no-underline ml-2 delete-link"
                    v-if="loc_index > 1"
                  >
                    <b-icon icon="x"></b-icon>
                  </a>

                  <twitter-picker
                    v-if="showLocationsColorPicker[loc_index]"
                    :defaultColors="defaultColors"
                    triangle="hide"
                    class="vc-t-picker"
                    v-model="order.location_colors[loc_index]"
                    @input="orderColorChanged"
                  >
                  </twitter-picker>

                  <b-modal
                    :id="`modal-search-locations-${loc_index}`"
                    :hide-header="true"
                    :hide-footer="true"
                    size="lg"
                  >
                    <SearchInput
                      :isStatic="true"
                      key="location-input-search"
                      index="Locations"
                      title="address"
                      subtitle="tags"
                      placeholder="搜尋"
                      :onClick="
                        (locationId) => {
                          selectLocation(
                            locationId,
                            loc_index,
                            `modal-search-locations-${loc_index}`
                          );
                        }
                      "
                    />
                  </b-modal>
                </b-row>

                <b-row
                  class="d-flex align-items-center mb-2 position-relative"
                  v-if="loc_index != 0"
                >
                  <label class="col-form-label col-form-label-sm mr-3 ml-3"
                    >Wait Time:
                  </label>
                  <b-form-input
                    size="sm"
                    class="number-input"
                    type="number"
                    v-model="order.wait_times[loc_index - 1]"
                    @change="waitTimeChanged($event, loc_index)"
                  ></b-form-input>
                </b-row>
              </b-col>
            </draggable>
            <b-row align-v="center" class="mb-5">
              <b-col cols="auto">
                <a href="#" @click.prevent="arrayAppend(order.locations)"
                  >新增目的地 +</a
                >
              </b-col>
              <b-col
                cols="auto"
                class="ml-auto text-right"
                v-if="polylineDistance"
              >
                <span class="dist-label">Total Distance</span>
                <span class="distance"
                  >{{ $_.round(polylineDistance, 1) }}KM</span
                >
              </b-col>
            </b-row>
            <div class="form-inline mb-3" v-if="!this.fromMobile">
              <label
                class="col-form-label col-form-label-sm mr-3"
                for="input-instruction"
                >司機指示:</label
              >
              <b-form-select
                size="sm"
                v-model="selectedFreqUseInstruction"
                class="pr-5"
                :options="mappedSettings.frequently_use_instructions"
                @change="setOrderComments"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >常用司機指示</b-form-select-option
                  >
                </template>
              </b-form-select>

              <span class="position-relative ml-auto">
                <a
                  href="#"
                  class="no-underline"
                  @click.prevent="
                    showInstructionsColorPicker = !showInstructionsColorPicker
                  "
                >
                  <b-icon
                    icon="droplet-fill"
                    v-if="order.instruction_color == 'transparent'"
                  ></b-icon>
                  <span
                    class="droplet"
                    :style="{
                      'background-color': order.instruction_color.hex,
                    }"
                    v-else
                  ></span>
                </a>

                <a
                  href="#"
                  class="no-underline ml-1 clear-color"
                  @click.prevent="order.instruction_color = 'transparent'"
                  v-if="order.instruction_color != 'transparent'"
                >
                  <b-icon-x></b-icon-x>
                </a>

                <twitter-picker
                  v-if="showInstructionsColorPicker"
                  :defaultColors="defaultColors"
                  triangle="hide"
                  v-model="order.instruction_color"
                  class="vc-t-picker"
                  @input="orderColorChanged"
                >
                </twitter-picker>
              </span>
            </div>
            <b-form-textarea
              size="sm"
              ref="input-instruction"
              v-model="order.instruction"
              v-if="!this.fromMobile"
              class="mb-4"
              rows="4"
              id="input-instruction"
              placeholder="Write here..."
            ></b-form-textarea>

            <div class="form-inline mb-3">
              <label
                class="col-form-label-sm col-form-label mr-3"
                for="input-remark"
                >備註 & 顧客要求:</label
              >

              <b-form-select
                v-model="selectedFreqUseRemark"
                size="sm"
                class="pr-5"
                :value="null"
                :options="mappedSettings.frequently_used_remark"
                @change="setOrderComments($event, 'remark')"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >常用備註</b-form-select-option
                  >
                </template>
              </b-form-select>

              <span class="position-relative ml-auto">
                <a
                  href="#"
                  class="no-underline"
                  @click.prevent="
                    showRemarkColorPicker = !showRemarkColorPicker
                  "
                >
                  <b-icon
                    icon="droplet-fill"
                    v-if="order.remark_color == 'transparent'"
                  ></b-icon>
                  <span
                    class="droplet"
                    :style="{
                      'background-color': order.remark_color.hex,
                    }"
                    v-else
                  ></span>
                </a>

                <a
                  href="#"
                  class="no-underline ml-1 clear-color"
                  @click.prevent="order.remark_color = 'transparent'"
                  v-if="order.remark_color != 'transparent'"
                >
                  <b-icon-x></b-icon-x>
                </a>

                <twitter-picker
                  v-if="showRemarkColorPicker"
                  :defaultColors="defaultColors"
                  triangle="hide"
                  v-model="order.remark_color"
                  class="vc-t-picker"
                  @input="orderColorChanged"
                >
                </twitter-picker>
              </span>
            </div>

            <b-form-textarea
              size="sm"
              rows="3"
              v-model="order.remark"
              id="input-remark"
              ref="input-remark"
            ></b-form-textarea>
          </div>

          <div class="form-inline mb-3" v-if="!this.fromMobile">
            <label
              class="col-form-label col-form-label-sm mr-3"
              for="input-instruction"
              >回覆客戶:</label
            >
            <b-form-select
              size="sm"
              v-model="selectedCustomerReply"
              class="pr-5"
              :options="mappedSettings.reply_customer"
              @change="setOrderComments($event, 'reply_customer')"
            >
              <template #first>
                <b-form-select-option :value="null"
                  >常用客戶回覆</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
          <b-form-textarea
            size="sm"
            ref="input-reply_customer"
            v-model="order.reply_customer"
            v-if="!this.fromMobile"
            class="mb-4"
            rows="4"
            id="input-reply_customer"
            placeholder="Write here..."
          ></b-form-textarea>

          <div class="w-100 mt-auto">
            <b-row align-v="start">
              <b-col cols="auto">
                <StatusDropdown v-model="order.status" />

                <div
                  class="invalid-feedback ml-2"
                  :class="{
                    'd-inline': $v.order.status.$invalid && formDirty,
                  }"
                >
                  Please input status
                </div>
              </b-col>
              <b-col cols="auto" class="ml-auto text-center">
                <b-button
                  @click="unsetOrder(order)"
                  v-if="order.orderId != null"
                  size="sm"
                  class="mr-2"
                  variant="link"
                  ><b-icon-trash /> Delete
                </b-button>
                <b-button
                  @click="cloneOrder"
                  v-if="order.orderId != null"
                  size="sm"
                  class="mr-2"
                  variant="link"
                  ><b-icon-clipboard /> Clone
                </b-button>
                <b-button variant="primary" @click="submitOrder">
                  <b-spinner
                    label="Spinning"
                    small
                    v-if="orderSubmitting"
                  ></b-spinner>
                  <template v-else>Submit</template>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import {
  isEmpty,
  map,
  truncate,
  filter,
  debounce,
  trim,
  cloneDeep,
  isArray,
  sortBy,
  isUndefined,
  intersection,
  forEach,
} from "lodash";
import moment from "moment";
import { gmapApi } from "vue2-google-maps";
import vSelect from "vue-select";

import { Twitter } from "vue-color";
import StatusDropdown from "../components/StatusDropdown.vue";
import MaskedInput from "vue-masked-input";
import { TheMask } from "vue-the-mask";
import draggable from "vuedraggable";
import Select2 from "v-select2-component";
import SearchInput from "@/components/SearchInput.vue";
import { createPopper } from "@popperjs/core";

let passengerStore = null;
const passengerValidator = function(value) {
  if (this && this.order.customer_isnot_passenger) {
    return !isEmpty(value.phone);
  } else {
    return true;
  }
};

const isValidTime = function(timeString) {
  let hourMin = timeString.split(":"),
    hr = Number(hourMin[0]),
    min = Number(hourMin[1]);

  if (!isFinite(hr) || hr > 23) return false;
  else if (!isFinite(min) || min > 59) return false;

  return true;
};

export default {
  components: {
    Select2,
    StatusDropdown,
    MaskedInput,
    SearchInput,
    draggable,
    TheMask,
    "twitter-picker": Twitter,
    vSelect,
  },
  props: {
    fromMobile: Boolean,
    showOrderForm: {
      type: Boolean,
      default: false,
    },
    passInOrder: {
      type: Object,
      default: null,
    },
    customer: Object,
    newCustomer: {
      type: Object,
      default: function() {
        return {
          name: "",
          phone: "",
        };
      },
    },
  },
  data() {
    return {
      moreDates: [],

      formDirty: false,
      selectedFreqUseInstruction: null,
      selectedFreqUseRemark: null,
      selectedCustomerReply: null,
      order: this.passInOrder,
      orderSubmitting: false,

      markers: [],
      mapCenter: { lat: 43.7184038, lng: -79.5181384 },
      mapZoom: 10,

      defaultColors: [
        "#999999",
        "#9900EF",
        "#F44E3B",
        "#FE9200",
        "#FCDC00",
        "#DBDF00",
        "#A4DD00",
        "#68CCCA",
        "#73D8FF",
        "#AEA1FF",
        "#FDA1FF",
        "#CCCCCC",
        "#D33115",
        "#E27300",
      ],
      showOrderColorPicker: false,
      showLocationsColorPicker: [],
      showInstructionsColorPicker: false,
      showRemarkColorPicker: false,
    };
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
      stored_drivers: (state) => state.drivers.drivers,
      mappedSettings: (state) => {
        let mysettings = {};

        forEach(state.settings.settings, (setting_v, k) => {
          mysettings[k] = map(setting_v, function(v) {
            return {
              value: v,
              text: truncate(v, {
                length: 24,
                separator: " ",
              }),
            };
          });
        });

        return mysettings;
      },
      selected_location: (state) => state.locations.selectedLocation,
      currentUser: (state) => state.user.userProfile,
    }),
    drivers() {
      return sortBy(
        Object.values(cloneDeep(this.stored_drivers)).map((d) => {
          return { value: d.driverId, text: d.name };
        }),
        function(d) {
          if (d.text) return d.text.toLowerCase();
          else return "";
        }
      );
    },

    orderOtherLuggages: {
      get() {
        if (this.order?.other_luggages?.length > 0)
          return this.order.other_luggages;
        else return [];
      },
    },
    /*orderFreqUseInstructions () {
      return map(this.settings.frequently_use_instructions, function(v) {
        return {
          value: v,
          text: truncate(v, {
            length: 24,
            separator: " ",
          }),
        };
      });
    },*/
    customerHasPassenger() {
      if (this.customer?.passenger) {
        return this.customer.passenger.length > 0;
      } else return false;
    },
    google: gmapApi,
    geocoder() {
      return new this.google.maps.Geocoder();
    },
    polylinePath: function() {
      let path = [];

      this.markers.forEach((m) => {
        if (m) {
          let position = m.position;
          if (position) path.push({ lat: position.lat(), lng: position.lng() });
        }
      });

      return path;
    },
    polylineDistance: function() {
      let paths = filter(this.polylinePath, (p) => !isEmpty(p));
      if (paths.length >= 2) {
        return this.mapHelpers.inKm(paths);
      }
      return "";
    },
    dateValidity() {
      if (
        !this.order.date ||
        !this.order.time ||
        this.order.time.indexOf("_") >= 0 ||
        !isValidTime(this.order.time)
      )
        return false;

      if (this.moreDates && this.moreDates.length > 0) {
        for (var i = this.moreDates.length - 1; i >= 0; i--) {
          let md = this.moreDates[i];
          if (!md.date || !md.time || !isValidTime(md.time)) return false;
        }
      }

      return true;
    },
  },
  watch: {
    passInOrder: {
      handler(newValue) {
        let nv = cloneDeep(newValue);

        if (!nv.passenger || isEmpty(nv.passenger)) nv.passenger = [];
        else if (!isArray(nv.passenger)) nv.passenger = [nv.passenger];
        if (!isArray(nv.locations_latlng)) nv.locations_latlng = [];

        this.order = Object.assign({}, this.order, nv);
        if (this.order.time && this.order.time.length < 5) {
          this.order.time = moment(this.order.time, "HH:mm").format("HH:mm"); // Convert to valid time format
        }

        // Only flag as airport when the pickup location is airport
        this.order.isAirport = this.order.locations?.[0]
          ?.toLowerCase()
          ?.includes("airport");
      },
      immediate: true,
    },
    "order.cost": {
      immediate: true,
      handler(nv) {
        if (this.order.status == "awaiting reply" && nv) {
          this.order.status = "answered";
        }
      },
    },
    "order.color": {
      immediate: true,
      handler(nv) {
        if (!nv) this.resetOrderColor("color", { hex: "#F44E3B" });
      },
    },
    "order.address_color": {
      immediate: true,
      handler(nv) {
        if (!nv) this.resetOrderColor("address_color");
      },
    },
    "order.instruction_color": {
      immediate: true,
      handler(nv) {
        if (!nv) this.resetOrderColor("instruction_color");
      },
    },
    "order.remark_color": {
      immediate: true,
      handler(nv) {
        if (!nv) this.resetOrderColor("remark_color");
      },
    },
    "order.location_colors": {
      immediate: true,
      handler(nv) {
        if (!isArray(nv) && isArray(this.order.locations)) {
          this.order.location_colors = this.arrayHelpers.emptyColorArray(
            this.order.locations.length
          );
        }
      },
    },
    "order.language": {
      immediate: true,
      handler(nv) {
        if (!["cantonese", "english", "mandarin"].includes(nv))
          this.order.language = "cantonese";
      },
    },
    "order.locations": {
      immediate: true,
      handler(nv, ov) {
        if (
          (isArray(nv) && !isUndefined(ov)) ||
          (isArray(nv) && !isArray(this.order.location_colors))
        ) {
          this.order.location_colors = this.arrayHelpers.emptyColorArray(
            nv.length
          );
        }

        if (
          isArray(nv) &&
          isUndefined(ov) &&
          !isArray(this.order.location_names)
        ) {
          this.order.location_names = this.arrayHelpers.emptyArray(nv.length);
        }

        if (isArray(nv) && !isArray(this.order.wait_times)) {
          this.order.wait_times = this.arrayHelpers.emptyArray(nv.length - 1);
        }

        // if (
        //   isUndefined(ov) &&
        //   isArray(this.order.locations_latlng) &&
        //   isArray(nv) &&
        //   this.order.locations_latlng.length == nv.length
        // ) {
        //   this.pinMapLocationsByLatLngs();
        // } else {
        //   this.pinMapLocations(nv);
        // }

        // if (!isUndefined(nv)) {
        //   let assignToGmapAuto = () => {
        //     if (this.$refs.gmapAutocomplete) {
        //       this.$refs["gmapAutocomplete"].forEach((v, auto_index) => {
        //         let autoInput = v.$el;
        //         if (
        //           !autoInput.value ||
        //           autoInput.value != this.order.locations[auto_index]
        //         ) {
        //           autoInput.value = this.order.locations[auto_index];
        //         }
        //       });

        //       clearInterval(assignGmapTimer);
        //     }
        //   };
        //   let assignGmapTimer = setInterval(assignToGmapAuto, 500);
        // }
      },
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width;

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "top",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            },
          },
        ],
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    },
    orderColorChanged() {
      this.showOrderColorPicker = this.showInstructionsColorPicker = this.showRemarkColorPicker = false;
      this.showLocationsColorPicker = [];
    },
    resetOrderColor(prop, defaultColor = "transparent") {
      this.order[prop] = defaultColor;
    },
    removeLocationColor(locIndex) {
      this.$set(this.order.location_colors, locIndex, "transparent");
      this.$forceUpdate();
    },
    selectText(e) {
      e.target.select();
    },
    onMultiDayClick(day) {
      this.moreDates.push({
        id: day.id,
        date: day.date,
        time: this.order.time,
      });

      this.moreDates.sort((a, b) => {
        if (a.date < b.date) return -1;
        else if (a.date > b.date) return 1;
        else 0;
      });
    },
    touchOrderLocations(place, i) {
      let gauto = this.$refs.gmapAutocomplete[i];
      if (place != null && gauto.$autocomplete) {
        let autoPlace = gauto.$autocomplete.getPlace();
        let formatted_address = autoPlace.formatted_address;

        let inputValue = formatted_address;
        if (
          !intersection(autoPlace.types, ["street_address", "premise"]).length
        ) {
          inputValue = `${place.name}`;
        }

        gauto.$el.value = inputValue;
        this.$set(this.order.locations, i, inputValue);
      } else {
        this.$set(this.order.locations, i, gauto.$el.value);
      }
    },
    pinMapLocationsByLatLngs() {
      this.markers = [];

      if (isArray(this.order.locations_latlng)) {
        let googleAPI_timer = setInterval(() => {
          if (this.google != null) {
            clearInterval(googleAPI_timer);

            for (var i = this.order.locations_latlng.length - 1; i >= 0; i--) {
              this.$set(
                this.markers,
                i,
                new this.google.maps.Marker({
                  position: this.order.locations_latlng[i],
                })
              );
            }
          }
        }, 300);

        this.fitMapBounds();
      }
    },
    pinMapLocations: debounce(function(locations) {
      this.markers = [];

      locations = filter(locations, (l) => !isEmpty(l));

      locations.forEach((l, li) => {
        let adr = trim(l.replace(/@.*/g, ""));
        if (adr) {
          this.geocoder.geocode(
            {
              address: adr,
              componentRestrictions: {
                country: "CA",
              },
            },
            (results, status) => {
              if (status === "OK" && results[0].geometry.location) {
                this.$set(
                  this.markers,
                  li,
                  new this.google.maps.Marker({
                    position: results[0].geometry.location,
                  })
                );
                this.$set(this.order.locations_latlng, li, {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                });

                this.fitMapBounds();
              } else {
                this.$set(this.order.locations_latlng, li, null);
              }
            }
          );
        }
      });
    }, 2000),
    fitMapBounds: debounce(function() {
      let map = this.$refs.mapRef;
      if (map) {
        if (this.markers.length > 1) {
          let bounds = new this.google.maps.LatLngBounds();
          for (var i = 0; i < this.markers.length; i++) {
            if (this.markers[i] && this.markers[i].getPosition()) {
              bounds.extend(this.markers[i].getPosition());
            }
          }
          map.fitBounds(bounds);
        } else if (this.markers.length == 1) {
          this.mapCenter = this.markers[0].getPosition();
        }
      }
    }, 1000),
    ...mapActions({
      saveOrder: "orders/set",
      deleteOrder: "orders/delete",
    }),
    async unsetOrder(order) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this order? (It cannot be undone)"
        )
        .then(async (value) => {
          if (value) {
            await this.deleteOrder(order.orderId);

            this.$notify({
              text: "Order removed successfully",
              type: "success",
            });

            this.$emit("orderSubmitted", null);
          }
        });
    },
    computeValidity($invalid) {
      return this.formDirty && $invalid ? false : null;
    },
    isPhoneNumber(value) {
      if (value)
        return [8, 10, 11].includes(value.replace(/[^\d]/g, "").length);
      else return false;
    },
    arrayAppend(my_array) {
      my_array.push("");
    },
    arrayDelete(my_array, i) {
      my_array.splice(i, 1);
    },
    otherLuggagesChanged(value, lg_index) {
      this.$set(this.order.other_luggages, lg_index, value);
    },
    locationNameChanged(value, loc_index) {
      this.$set(
        this.order.location_names ??
          this.arrayHelpers.emptyColorArray(this.order.locations.length),
        loc_index,
        value
      );
    },
    waitTimeChanged(value, loc_index) {
      this.$set(
        this.order.wait_times ??
          this.arrayHelpers.emptyColorArray(this.order.locations.length - 2),
        loc_index - 1,
        value
      );
    },
    setOrderComments(selectedText, commentKey = "instruction") {
      let orderComments = this.order[commentKey] ?? "";

      if (
        orderComments?.length &&
        orderComments?.substr(orderComments.length - 1) != "\n"
      ) {
        orderComments += "\n";
      }
      orderComments += selectedText + "\n";

      console.log(orderComments);

      this.order[commentKey] = orderComments;

      this.$nextTick(() => {
        const inputComment = this.$refs[`input-${commentKey}`]?.$el;
        console.log(inputComment);
        console.log(commentKey);
        if (inputComment) {
          inputComment.scrollTop = inputComment?.scrollHeight;
          inputComment.focus();
        }
      });

      this.selectedFreqUseInstruction = null;
      this.selectedFreqUseRemark = null;
      this.selectedCustomerReply = null;
    },
    newOrderPassenger(passenger = {}) {
      if (!isArray(this.order.passenger)) {
        this.order.passenger = [];
      }
      this.order.passenger.push(cloneDeep(passenger));
      this.$bvModal.hide("customer-passengers-modal");
    },
    async selectLocation(locationId, loc_index, bvModal_id) {
      await this.$store.dispatch("locations/getLocation", locationId);

      if (this.selected_location) {
        let location_string = this.selected_location.address;
        if (isArray(this.selected_location.tags)) {
          this.selected_location.tags.forEach((t) => {
            location_string += " @" + t;
          });
        }

        this.$set(this.order.locations, loc_index, location_string);
        // this.$v.order.locations.$touch();

        if (bvModal_id) this.$root.$emit("bv::hide::modal", bvModal_id);
      }
    },
    async createDriver(option) {
      let selectedDriverName = option.text,
        existingDriver = find(
          this.drivers,
          (d) =>
            d.text.toLowerCase().trim() ==
            selectedDriverName.toLowerCase().trim()
        );

      if (!existingDriver) {
        const h = this.$createElement;
        const messageVNode = h("p", {
          domProps: {
            innerHTML: `Create a new driver with the name ${option.text}? <br><small>(Please make sure this driver name is not in the database)</small>`,
          },
        });

        this.$bvModal
          .msgBoxConfirm([messageVNode], {
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            cancelTitle: "Cancel",
            noCloseOnEsc: true,
            noCloseOnBackdrop: true,
            headerClass: "border-bottom-0 pb-0",
            footerClass: "border-top-0 pt-0",
            centered: true,
          })
          .then(async (value) => {
            if (value) {
              await this.setDriver({
                driverId: null,
                data: { name: option.text },
              }).then((docRef) => {
                this.$set(this.order, "driverId", docRef.id);
              });
            }
          });
      }
    },
    selectDriver(option) {
      let selectedDriverName = option.text,
        existingDriver = this.drivers.find(
          (d) =>
            d.text.toLowerCase().trim() ==
            selectedDriverName.toLowerCase().trim()
        );

      if (existingDriver) {
        this.$set(this.order, "driverId", existingDriver.value);
        // this.order.driverId = existingDriver.value;
      }
    },
    clearDriver() {
      this.$set(this.order, "driverId", "");
    },
    viberClick(orderId) {
      const text = this.stringHelpers.clipboardMsgFormatter(this.order);
      navigator.clipboard.writeText(text);

      this.saveOrder({
        orderId,
        data: Object.assign(this.order, {
          notice: true,
          color: null,
          instruction_color: null,
          remark_color: null,
        }),
        user: this.currentUser,
      });

      this.$notify({
        text: "Saved successfully and order data copied!",
        type: "success",
      });
    },
    async submitOrder() {
      this.formDirty = false;
      this.orderSubmitting = true;

      const _order = this.order;
      _order["fromMobile"] = _order?.fromMobile || false;
      _order["customerId"] = _order?.customerId || this.customer?.customerId;
      _order["customer"] = isEmpty(this.customer)
        ? this.newCustomer
        : {
            name: this.customer.name,
            phone: this.customer.phone,
            otherPhones: this.customer?.otherPhones ?? null,
            otherPhoneDescriptions: this.customer?.otherPhoneDescriptions ?? [],
          };

      // Merge date and time into one field so it's easier for queries
      _order["dateTime"] = moment(_order.date + " " + _order.time).toDate();
      if (
        _order["status"].toLowerCase() === "confirmed" &&
        this.passInOrder.status.toLowerCase() !== "confirmed"
      ) {
        _order["adminConfirmAt"] = moment().toDate();
        _order["hasConfirmed"] = true;
      }

      if (
        this.$v.$invalid ||
        // !this.isPhoneNumber(_order.customer.phone) ||
        !this.dateValidity
      ) {
        this.formDirty = true;

        this.$bvModal.msgBoxOk(
          "There are some error in the input. Please double check.",
          {
            buttonSize: "sm",
            centered: true,
            size: "sm",
          }
        );
      } else {
        this.$emit("orderSubmitting", this.order);

        try {
          let myOrder = cloneDeep(this.order);
          // If you don't specify an oid, then it will create a new document
          const val = await this.saveOrder({
            orderId: myOrder.orderId ?? null,
            data: myOrder,
            user: this.currentUser,
          });

          if (val?.error) {
            this.$notify({
              text: val.error,
              type: "error",
            });
            return;
          }

          if (this.moreDates && this.moreDates.length > 0) {
            for (var i = this.moreDates.length - 1; i >= 0; i--) {
              let moreOrder = cloneDeep(this.order);
              let myMoreDate = new moment(this.moreDates[i].date);

              moreOrder.date = myMoreDate.format("YYYY-MM-DD");
              moreOrder.time = this.moreDates[i].time;

              let mTime = moreOrder.time.split(":");
              moreOrder.dateTime = myMoreDate
                .hour(Number(mTime[0]))
                .minute(Number(mTime[1]))
                .toDate();

              await this.saveOrder({
                orderId: null,
                data: moreOrder,
                user: this.currentUser,
              });
            }
          }

          if (
            !myOrder.orderId &&
            (location.pathname == "/order" ||
              (location.pathname == "/mobile" &&
                this.order["status"].toLowerCase() == "confirmed"))
          ) {
            const h = this.$createElement;
            const _this = this;

            const messageVNode = h("div", [
              h("p", { class: [] }, ["Data was submitted successfully"]),
              h(
                "a",
                {
                  attrs: {
                    href: "#",
                  },
                  on: {
                    click: function(event) {
                      if (event) event.preventDefault();

                      _this.viberClick(val.id);
                    },
                  },
                },
                ["Copy Viber >>"]
              ),
            ]);

            this.$bvModal
              .msgBoxOk(messageVNode, {
                title: "Confirmation",
                size: "sm",
                buttonSize: "sm",
                okVariant: "primary",
                noCloseOnEsc: true,
                noCloseOnBackdrop: true,
                headerClass: "border-bottom-0 pb-0",
                footerClass: "border-top-0 pt-0",
                centered: true,
              })
              .then(() => {
                location.href = location.pathname;
              });
          } else {
            this.$emit("orderSubmitted", this.order.orderId ?? null);
          }
        } catch {
          this.$emit("orderSubmitError");
        }
      }

      this.orderSubmitting = false;
    },
    resetOrder() {
      this.formDirty = false;
      this.$emit("resetOrder");
    },
    autoGMapMounted() {
      if (this.$refs["gmapAutocomplete"].length > 0) {
        let gmapAutocomplete_timer = setInterval(() => {
          this.$refs["gmapAutocomplete"].forEach((v, auto_index) => {
            if (v.$autocomplete) {
              if (this.$refs["mapRef"]) {
                // let mapObj = this.$refs["mapRef"].$mapObject;

                var southWest = new this.google.maps.LatLng(
                  43.036972,
                  -80.431472
                );
                var northEast = new this.google.maps.LatLng(
                  43.960185,
                  -78.755891
                );
                var hyderabadBounds = new this.google.maps.LatLngBounds(
                  southWest,
                  northEast
                );
                v.$autocomplete.setBounds(hyderabadBounds);
              }
              clearInterval(gmapAutocomplete_timer);
            }
          });
        }, 1000);
      }
    },
    cloneOrder() {
      this.$store.dispatch("orders/clone", this.order);
      this.$router.push("order");
    },
  },
  validations: {
    order: {
      language: { required },
      // payment_method: { required },
      /*date: { required },
      time: { required },*/
      // passenger: { passengerValidator },
      /*locations: {
        $each: { required },
      },*/
      status: { required },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variable";

#input-box {
  > div {
    height: 100%;
  }

  .currency-input,
  .number-input {
    width: 84px !important;
    text-align: center;
    padding-right: 5px;
    padding-left: 10px;
  }

  .currency-dollar {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  #gmap-wrapper {
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 15px;
    position: relative;
    padding-bottom: 55%;

    .vue-map-container {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.input-driver .vs__select {
  overflow: initial !important;
}

.input-group-prepend {
  cursor: move;
}

.input-group-prepend,
.input-group-append {
  background-color: #fff;
  border-radius: 4px;
}

.passenger-caret,
.passenger-remove {
  line-height: 38px;
  margin-right: 5px;
}

.passenger-remove,
.clear-color {
  svg {
    transform: scale(1.35);
  }
}

.passenger-select {
  svg {
    transform: scale(1.5);
  }
}

.dist-label {
  font-size: 0.85rem;
}

.distance {
  font-size: 2rem;
  display: block;
  line-height: 1;
}

.delete-link {
  font-size: 1.5rem;
}

#first-col {
  max-width: 630px;
  position: relative;

  &:before {
    content: "";
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: $border-color;
    position: absolute;
  }
}

.vc-t-picker {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 9;
  margin-top: 10px;
}

.droplet {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: #000;
  border-radius: 50%;
  vertical-align: -0.15em;
}
</style>
