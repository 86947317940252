<template>
  <div class="form-inline">
    <b-form-select v-model="dateRangeChoice" @change="optionChanged" size="sm">
      <b-form-select-option
        v-for="(dateRangeOption, dr_index) in ['Current', 'Date Range']"
        :key="`dateRange-${dr_index}`"
        :value="dateRangeOption"
        >{{ dateRangeOption }}</b-form-select-option
      >
    </b-form-select>

    <date-range-picker
      v-if="dateRangeChoice == 'Date Range'"
      class="ml-2 date-range-text"
      ref="picker"
      :opens="openPosition || 'right'"
      :autoApply="true"
      :showWeekNumbers="false"
      @update="dateRangeUpdated"
      v-model="dateRange"
    >
    </date-range-picker>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  components: { DateRangePicker },
  props: ["value", "openPosition", "defaultRange"],
  data() {
    let dateRangeChoice = this.defaultRange || "Current";
    let value = this.value;

    return {
      dateRangeChoice,
      dateRange: value,
    };
  },
  watch: {
    value(nv) {
      this.dateRange = nv;
    },
    dateRangeChoice(nv) {
      if (nv == "Current") {
        let currentRange = {
          startDate: moment()
            .subtract(90, "minutes")
            .toDate(),
          // endDate: moment().endOf('day').toDate(),
        };
        this.dateRange = currentRange;
        this.$emit("input", currentRange);
      }
    },
  },
  methods: {
    dateRangeUpdated() {
      let startDate = moment(this.dateRange.startDate),
        endDate = moment(this.dateRange.endDate);

      this.$emit("input", {
        startDate: startDate.startOf("day").toDate(),
        endDate: endDate.endOf("day").toDate(),
      });
    },
    optionChanged() {
      this.$emit("optionChanged", this.dateRangeChoice);
    },
  },
};
</script>

<style lang="scss">
.date-range-text {
  background: transparent;

  .form-control {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}
</style>
