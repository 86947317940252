import auth from "./auth";
import user from "./user";
import orders from "./orders";
import drivers from "./drivers";
import customers from "./customers";
import settings from "./settings";
import locations from "./locations";

export default {
  auth,
  user,
  orders,
  drivers,
  customers,
  settings,
  locations,
};
