import firebase from "firebase/compat/app";

const env = process.env;

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: env.VUE_APP_FB_API_KEY,
  authDomain: env.VUE_APP_AUTH_DOMAIN,
  projectId: env.VUE_APP_PROJECT_ID,
  storageBucket: env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: env.VUE_APP_MESSAGING_SENDER_ID,
  appId: env.VUE_APP_APP_ID,
};

/* const firebaseConfigProd = {
  apiKey: "AIzaSyDdxa_5-tjPZD7OjTMiRiFn1j5msYrZQW4",
  authDomain: "linktransportapp.firebaseapp.com",
  projectId: "linktransportapp",
  storageBucket: "linktransportapp.appspot.com",
  messagingSenderId: "539400721545",
  appId: "1:539400721545:web:923e2fbdc33855e74e2258",
};

const firebaseConfigDev = {
  apiKey: "AIzaSyAyR5pgUEnHr8cwtf7P1lh9Bz4IKaOVuiE",
  authDomain: "order-app-ae43e.firebaseapp.com",
  projectId: "order-app-ae43e",
  storageBucket: "order-app-ae43e.appspot.com",
  messagingSenderId: "432910833552",
  appId: "1:432910833552:web:194c3a9c1941e81329c515",
  measurementId: "G-GW9T2SMLL0",
}; */

firebase.initializeApp(firebaseConfig);

firebase
  .firestore()
  .enablePersistence()
  .catch((err) => {
    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == "unimplemented") {
      window.alert("The current browser does not support offline presistance");
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
// Subsequent queries will use persistence, if it was enabled successfully
